import React, { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/styles";
import PopUp from "./../OptimizationPopUp/Popup";
import SourceTableCell from "./components/SourceTableCell/SourceTableCell";
import TopicTableCell from "./components/TopicTableCell";
import TypographyTableCell from "./components/TypographyTableCell/TypographyTableCell";
import HeadCells from "./components/HeadCells/HeadCells";
import ScoreTypography from "../../../AEOAssessmentReport/components/EntityRecognitionScore/components/ScoreTypography/ScoreTypography";
import RiskTableCell from "./components/RiskTableCell";
import ArrowRightIcon from "../../../../CommonComponents/customIcons/ArrowRightIcon";
import { Typography } from "@mui/material";
import StatusTableCell from "./components/StatusTableCell/StatusTableCell";
import { API_URL } from "../../../../api";
import { useParams } from "react-router-dom";
import AnimationLoader from "../../../../CommonComponents/AnimationLoader/AnimationLoader";
import SearchInput from "../../../../CommonComponents/SearchInput";

const SourceTrackingTable = ({ isOverview, date, selecetdLLm }) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("-created_at");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [searchQuery, setSearchQuery] = useState();
  const { brandId, topicName } = useParams();
  const topic = topicName ? topicName.replace(/-/g, " ") : "";

  const fetchData = async (
    page,
    rowsPerPage,
    order,
    orderBy,
    start_date,
    end_date
  ) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${API_URL}/aeo/${brandId}/source-tracking/`,
        {
          params: {
            page: page + 1,
            page_size: rowsPerPage,
            sort: order === "asc" ? orderBy : `-${orderBy}`,
            startDate: start_date,
            endDate: end_date,
            llm: selecetdLLm[0],
            source: searchQuery,
            topic: topic,
          },
        }
      );
      setRows(response.data.results);
      setTotalCount(response.data.count);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const start_date = date[0] && date[0].format("YYYY-MM-DD");
    const end_date = date[1] && date[1].format("YYYY-MM-DD");
    fetchData(page, rowsPerPage, order, orderBy, start_date, end_date);
  }, [
    page,
    rowsPerPage,
    order,
    orderBy,
    date,
    selecetdLLm,
    searchQuery,
    topic,
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedRow({});
    setOpen(false);
  };
  return (
    <Box sx={{ width: "100%", marginTop: 4 }}>
      <Box sx={{ marginBottom: 4 }}>
        <SearchInput
          placeholder="Search by Source "
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          width="268px"
        />
      </Box>

      <TableContainer
        sx={{
          overflow: "hidden",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      >
        <Table
          sx={{ minWidth: 750, tableLayout: "auto" }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <HeadCells
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={totalCount}
            isOverview={isOverview}
          />
          <TableBody>
            {loading ? (
              <TableRow
                sx={{
                  borderBottom: `1px solid ${theme.palette.border.quaternary}`,
                  "& td": {
                    borderBottom: `1px solid ${theme.palette.border.quaternary}`,
                  },
                }}
              >
                <TableCell colSpan={9} align="center">
                  <AnimationLoader />
                </TableCell>
              </TableRow>
            ) : rows.length > 0 ? (
              rows.map((row, index) => (
                <TableRow
                  key={index}
                  hover
                  tabIndex={-1}
                  sx={{
                    borderBottom: `1px solid ${theme.palette.border.quaternary}`,
                    "& td": {
                      borderBottom: `1px solid ${theme.palette.border.quaternary}`,
                    },
                    "&:hover .should-optimize-typography": {
                      backgroundColor: theme.palette.background.quaternary,
                      borderRadius: "30px",
                      padding: "5px 8px",
                      maxWidth: "fit-content",
                    },
                  }}
                >
                  <TableCell align="left">
                    <SourceTableCell
                      value={row.source}
                      category={row.category}
                      navigate={row.source_url}
                    />
                  </TableCell>
                  {isOverview ? (
                    <TableCell align="left">
                      <TopicTableCell topic={row.topic} />
                    </TableCell>
                  ) : (
                    <TableCell align="left">
                      <StatusTableCell status={row.source_status} />
                    </TableCell>
                  )}
                  <TableCell align="left">
                    <TypographyTableCell value={row.type} />
                  </TableCell>
                  <TableCell align="left">
                    <ScoreTypography
                      iconType="face"
                      score={row?.sentiment}
                      fontSize={12}
                      fontWeight={400}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <TypographyTableCell value={row.brand_mentions} />
                  </TableCell>
                  <TableCell align="left">
                    <TypographyTableCell value={row.influence_score} />
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ color: theme.palette.text.primary, fontSize: "12px" }}
                  >
                    <RiskTableCell
                      value={row.impact_score}
                      piriority={row.optimization_priority}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      sx={{
                        color: theme.palette.text.primary,
                        fontSize: "12px",
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "row",
                        columnGap: 0.5,
                        borderRadius: "30px",
                        padding: "5px 8px",
                        maxWidth: "fit-content",
                        cursor: "pointer",
                      }}
                      className="should-optimize-typography"
                      onClick={() => handleOpen(row)}
                    >
                      {row.should_optimize ? "Optimize" : "View"}{" "}
                      <ArrowRightIcon color={theme.palette.text.primary} />
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        slotProps={{
          select: {
            sx: {
              "& .MuiSelect-icon": {
                color: theme.palette.text.primary,
              },
            },
            MenuProps: {
              sx: {
                ".MuiPaper-root": {
                  backgroundColor: theme.palette.background.secondary,
                  boxShadow: "none",
                },
                ".MuiTablePagination-menuItem": {
                  ":hover": {
                    backgroundColor: theme.palette.dropdown.hover,
                  },
                  backgroundColor: theme.palette.background.secondary,
                },
              },
            },
          },
          actions: {
            previousButton: {
              sx: {
                color: theme.palette.text.primary,
                "&.Mui-disabled": {
                  color: theme.palette.text.disabled,
                },
              },
            },
            nextButton: {
              sx: {
                color: theme.palette.text.primary,
                "&.Mui-disabled": {
                  color: theme.palette.text.disabled,
                },
              },
            },
          },
        }}
      />
      {selectedRow && (
        <PopUp
          open={open}
          onClose={handleClose}
          initialSelected={selectedRow}
          setInitialSelected={setSelectedRow}
        />
      )}
    </Box>
  );
};
export default SourceTrackingTable;
