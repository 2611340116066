import WorkspacePageTemplate from "../Workspace/components/PageTemplate/PageTemplate";
import { CircularProgress, Grid } from "@mui/material";
import Doc from "../../CommonComponents/customIcons/doc";
import StepperModal from "../../CommonComponents/StepperModal";
import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "../../api";
import GenerateBlogModal from "../Dashboard/components/GenerateBlogModal";
import IncompleteDna from "../Dashboard/components/IncompleteDna/IncompleteDna";
import RecentSection from "../Dashboard/components/RecentSection/RecentSection";
import ShortcutCard from "../Dashboard/components/ShorcutCard/ShortcutsCard";
import Business from "../BrandDNA/components/BrandDNAForm/components/Business";
import Persona from "../BrandDNA/components/BrandDNAForm/components/Persona";
import BrandVoice from "../BrandDNA/components/BrandDNAForm/components/BrandVoice/BrandVoice";
import Global from "../BrandDNA/components/BrandDNAForm/components/Global";
import Competition from "../BrandDNA/components/BrandDNAForm/components/Competition/Competition";
import { getValidationSchema, steps } from "../BrandDNA/conts";
import KnowledgeBaseIcon from "../../CommonComponents/customIcons/KnowledgeBaseIcon";
import { useTheme } from "@emotion/react";

const AiContentWriter = () => {
  const { brandId: id } = useParams();
  const brandId = id || localStorage.getItem("brandId");

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDnaCompleted, setIsDnaCompleted] = useState(false);
  const [openBlogDrawer, setOpenBlogDrawer] = useState(false);
  const [openDNAModal, setOpenDNAModal] = useState(false);
  const userBrands = JSON.parse(localStorage.getItem("loggedInUser"))?.brands;
  const theme = useTheme();

  const companyName =
    localStorage.getItem("worskapceName") ||
    userBrands?.filter((brand) => parseInt(brand.id) === parseInt(brandId))[0]
      ?.name;

  const shortcuts = useMemo(
    () => [
      {
        icon: <KnowledgeBaseIcon color={theme.palette.text.primary} />,
        title: "Write a blog",
        description:
          "Craft engaging, informative, SEO-friendly blog posts, tailored to your audience",
        backgroundColor: "#EBFFC1",
        onClick: () => setOpenBlogDrawer(true),
        isReady: true,
      },
    ],
    [setOpenBlogDrawer, theme]
  );

  useEffect(() => {
    axios
      .get(`${API_URL}/brands/${brandId}/overview`)
      .then((response) => {
        const workspaceData = response.data;
        localStorage.setItem("workspace", JSON.stringify(workspaceData));
        const iscomplete =
          workspaceData.business_step_completed &&
          workspaceData.persona_step_completed &&
          workspaceData.brand_voice_step_completed &&
          workspaceData.competitors_step_completed &&
          workspaceData.global_step_completed;
        setIsDnaCompleted(iscomplete);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setError("Failed to fetch user data");
        setLoading(false);
      });
  }, [brandId]);

  const initialValues = useMemo(
    () => ({
      // business
      name: companyName || "",
      description: "",
      conversion_goals: [],
      industry: "",
      business_model: "",
      features: ["", ""],
      benefits: ["", ""],
      market_focus: "",
      usp: "",
      country: "",
      primary_language: "",
      website: "",
      secondary_countries: [],

      // persona
      age_ranges: [],
      genders: [],
      interests: [],
      job_functions: [],
      job_titles: [],
      desires: ["", ""],
      pain_points: ["", ""],

      // brand voice
      brand_voice: "",
      not_brand_voice: "",
      formality_tone: "",
      core_themes: [],
      emotions: [],
      styles: [],

      // competitors
      competitors: [{ name: "", website: "" }],
    }),
    // eslint-disable-next-line
    [brandId]
  );

  const handleSave = useCallback(
    (values) => {
      setLoading(true);
      axios
        .patch(`${API_URL}/brands/${brandId}`, values)
        .then((response) => {
          setLoading(false);
          window.location.href = `/dashboard/${brandId}/`;
        })
        .catch((err) => {
          setLoading(false);
        });
    },
    [brandId]
  );

  const getStepContent = useCallback((step) => {
    switch (step) {
      case 0:
        return <Business brandDetails={{}} />;
      case 1:
        return <Persona brandDetails={{}} />;

      case 2:
        return <BrandVoice brandDetails={{}} />;
      case 3:
        return <Global brandDetails={{}} />;
      case 4:
        return <Competition brandDetails={{}} />;

      default:
        return <Business brandDetails={{}} />;
    }
  }, []);

  useEffect(() => {
    if (!isDnaCompleted && !loading) setOpenDNAModal(true);
    else setOpenDNAModal(false);
  }, [isDnaCompleted, loading]);
  return loading ? (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "60%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        style={{
          width: 20,
          height: 20,
          color: "#2D333A",
        }}
      />
    </div>
  ) : (
    <WorkspacePageTemplate title="AI Content Writer">
      {!isDnaCompleted && <IncompleteDna />}
      <Grid
        container
        spacing={3}
        direction="row"
        alignItems="center"
        sx={{ marginTop: 3 }}
      >
        {shortcuts.map((card, index) => {
          return (
            <Grid item xs={8} sm={4} md={4} key={index}>
              <ShortcutCard card={card} disabled={!isDnaCompleted} />
            </Grid>
          );
        })}
      </Grid>
      <RecentSection />
      <GenerateBlogModal
        openBlogDrawer={openBlogDrawer}
        setOpenBlogDrawer={setOpenBlogDrawer}
        brandId={brandId}
      />
      <StepperModal
        open={openDNAModal}
        handleClose={() => setOpenDNAModal(false)}
        setOpenModal={setOpenDNAModal}
        modalName="Brand DNA"
        title={companyName}
        steps={steps}
        initialStep={0}
        getStepContent={getStepContent}
        formikChildren={(Business, Persona, BrandVoice, Competition)}
        initialValues={initialValues}
        getValidationSchema={getValidationSchema}
        handleSave={handleSave}
        loading={loading}
      />
    </WorkspacePageTemplate>
  );
};
export default AiContentWriter;
