import { Box, Typography } from "@mui/material";
import ListItemWithText from "../../../OwnedOperatedRecommendations/components/ListItemWithText";
import SectionWithCopy from "../../../SectionWithCopy";

const bulletPoints = ["content_type", "outreach_method", "recipient"];

const OutreachMessagesSection = ({ data, mapSections }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}>
      <ListItemWithText value="Reach out to the publisher, editor, or author of the source to request edits that will increase your brand’s AI search visibility." />
      <Box sx={{ ml: 2 }}>
        {Object.entries(data)
          .filter(([key]) => bulletPoints.includes(key))
          .map(([key, value]) => (
            <ListItemWithText
              key={key}
              label={mapSections[key] || key}
              value={value}
            />
          ))}
      </Box>
      <SectionWithCopy
        text={data.message_body}
        primaryText={
          <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
            Subject: <span style={{ fontWeight: 400 }}>{data.subject}</span>
          </Typography>
        }
      />
    </Box>
  );
};
export default OutreachMessagesSection;
