import EarnedRecommendations from "./components/EarnedRecommendations/EarnedRecommendations";
import OwnedOperatedRecommendations from "./components/OwnedOperatedRecommendations/OwnedOperatedRecommendations";

const Recommendation = ({ data, isEarned }) => {
  return isEarned ? (
    <EarnedRecommendations data={data} />
  ) : (
    <OwnedOperatedRecommendations data={data} />
  );
};

export default Recommendation;
