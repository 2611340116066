import {
  List,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandLess";
import RightArrow from "@mui/icons-material/KeyboardArrowRightOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const CollapsibleMenuItem = ({
  isCollapsed,
  featureFlags,
  open,
  onClick,
  icon: Icon,
  primaryText,
  children,
  canAccess,
}) => {
  const theme = useTheme();
  return (
    <>
      {!isCollapsed && (
        <ListItem
          onClick={onClick}
          sx={{
            p: "5px 8px",
            margin: "0 0 5px 0",
            borderRadius: "8px",
            justifyContent: "flex-start",
            opacity: canAccess ? 1 : 0.38,
            "&:hover": {
              backgroundColor: "background.hover",
              borderRadius: "8px",
              cursor: "pointer",
            },
          }}
        >
          <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
            <Icon color={theme.palette.text.tertiary} />
          </ListItemIcon>
          <ListItemText
            primary={primaryText}
            sx={{ color: "text.secondary" }}
            primaryTypographyProps={{
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "18px",
            }}
          />
          {canAccess ? (
            open ? (
              <ExpandMore
                sx={{ color: "text.secondary", width: 18, height: 18 }}
              />
            ) : (
              <RightArrow
                sx={{ color: "text.secondary", width: 18, height: 18 }}
              />
            )
          ) : (
            <LockOutlinedIcon style={{ fontSize: 18 }} />
          )}
        </ListItem>
      )}
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        sx={{
          ml: isCollapsed ? 0 : "24px",
          mb: isCollapsed ? 0 : "5px",
        }}
      >
        <List
          component="div"
          disablePadding
          sx={{
            gap: isCollapsed ? "12px" : "5px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {!isCollapsed && children}
        </List>
      </Collapse>
    </>
  );
};
export default CollapsibleMenuItem;
