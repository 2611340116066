import { Box, Typography } from "@mui/material";
import ListItemWithText from "../../../OwnedOperatedRecommendations/components/ListItemWithText";
import SectionWithCopy from "../../../SectionWithCopy";

const bulletPoints = ["expected_impact", "suggested_placement"];

const formatMessage = (data) => {
  const keysToInclude = [
    "headline",
    "introduction",
    "key_points",
    "conclusion",
  ];

  return keysToInclude
    .filter((key) => data[key] && data[key].length > 0)
    .map((key) => {
      const formattedKey = key
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());

      if (Array.isArray(data[key])) {
        return `**${formattedKey}**:\n  • ${data[key].join("\n  • ")}`;
      } else {
        return `**${formattedKey}**: ${data[key]}`;
      }
    })
    .join("\n\n");
};

const NewContentSuggestionSection = ({ data, mapSections }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}>
      <ListItemWithText value="Create new content based on the following outline to increase your brand’s AI search visibility." />
      <SectionWithCopy text={formatMessage(data)} />
      {Object.entries(data)
        .filter(([key]) => bulletPoints.includes(key))
        .map(([key, value]) => (
          <ListItemWithText
            key={key}
            label={mapSections[key] || key}
            value={value}
          />
        ))}
    </Box>
  );
};
export default NewContentSuggestionSection;
