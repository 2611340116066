import { TableCell, TableHead, TableRow, useTheme } from "@mui/material";
import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TableSortLabel from "@mui/material/TableSortLabel";

const HeadCells = ({ order, orderBy, onRequestSort, isOverview }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const theme = useTheme();

  const getSortIcon = (cellId) => {
    if (orderBy !== cellId) {
      return (
        <UnfoldMoreIcon
          sx={{ color: "text.quaternary", width: 17, height: 17 }}
        />
      );
    }
    return order === "asc" ? (
      <KeyboardArrowUpIcon
        sx={{ color: "text.quaternary", width: 17, height: 17 }}
      />
    ) : (
      <KeyboardArrowDownIcon
        sx={{ color: "text.quaternary", width: 17, height: 17 }}
      />
    );
  };
  const cells = [
    {
      id: "source_url",
      numeric: false,
      label: "Source",
      tooltipMessage:
        "The URL referencing your brand, a competitor, or tracked search topic.",
    },
    {
      id: isOverview ? "topic" : "status",
      numeric: true,
      label: isOverview ? "Topic" : "Status",
      tooltipMessage: isOverview
        ? "The tracked search topic for analyzing the source’s relevance and visibility."
        : "Indicates the current stage of source optimization (Inbox, In Progress, Completed, Archived). Sources without a status do not currently require optimization.",
    },
    {
      id: "type",
      numeric: true,
      label: "Type",
      tooltipMessage:
        "Indicates the source’s ownership classification: Owned (brand-controlled), Earned (outside brand's direct control), or Operated (partially brand-controlled; e.g., your brand’s social media).",
    },
    {
      id: "sentiment",
      numeric: true,
      label: "Sentiment",
      tooltipMessage:
        "The tone (positive, neutral, negative) towards your brand within the source.",
    },
    {
      id: "mentions",
      numeric: true,
      label: "Mentions",
      tooltipMessage:
        "The number of times your brand is mentioned within the source.",
    },
    {
      id: "quality",
      numeric: true,
      label: "Quality",
      tooltipMessage:
        "Indicates the degree to which the AI model uses this source to generate outputs and answers.Scores are out of 100. Higher scores indicate the source is more AI-friendly or favored by AI.",
    },
    {
      id: "avi",
      numeric: true,
      label: "AVI",
      tooltipMessage:
        "Evaluates how strongly each source impacts your brand’s AI search visibility by factoring in frequency, ranking, mention quality, and sentiment. Scores are out of 100. Higher scores indicate the source has a stronger, more positive impact on AI search visibility.",
    },
    {
      id: "action",
      numeric: true,
      label: "Action",
      tooltipMessage:
        "Indicates whether the source requires optimization. Sources without an action do not currently require optimization.",
    },
  ];
  return (
    <TableHead
      style={{
        backgroundColor: theme.palette.background.tertiary,
        padding: 0,
      }}
    >
      <TableRow>
        {cells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              fontSize: 14,
              fontWeight: 500,
              color: theme.palette.text.primary,
              borderRight:
                index !== cells.length - 1
                  ? `1px solid ${theme.palette.border.tertiary}`
                  : "none",
              borderBottom: "none",
              padding: "9px",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{
                display: "flex",
                flexDirection: "row",
                columnGap: 0.5,
                "& .MuiTableSortLabel-icon": {
                  display: "none",
                },
              }}
              IconComponent={() => getSortIcon(headCell.id)}
            >
              {headCell.label}

              {headCell.tooltipMessage && (
                <Tooltip
                  title={
                    <div style={{ whiteSpace: "pre-wrap" }}>
                      {headCell.tooltipMessage}
                    </div>
                  }
                >
                  <InfoOutlinedIcon
                    sx={{ color: "text.tertiary", width: 15, height: 15 }}
                  />
                </Tooltip>
              )}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
export default HeadCells;
