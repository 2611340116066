import ListDisplay from "../../ListDisplay";

const Sentiment = ({ data, mapSections }) => {
  return (
    <ListDisplay
      data={data}
      mapSections={mapSections}
      excludedKeys={["section", "priority"]}
      extraFields={[
        { key: "sentiment_score", label: "Sentiment Score" },
        { key: "sentiment_analysis", label: "Sentiment Analysis" },
      ]}
      nestedKey="optimization_recommendations"
    />
  );
};

export default Sentiment;
