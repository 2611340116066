import { CircularProgress, Divider, Grid, Typography } from "@mui/material";
import PageTemplate from "../../CommonComponents/PageTemplate/PageTemplate";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "../../api";
import axios from "axios";
import dayjs from "dayjs";
import IncompleteDna from "./components/IncompleteDna/IncompleteDna";
import RecentSection from "./components/RecentSection/RecentSection";
import GenerateBlogModal from "./components/GenerateBlogModal";
import StepperModal from "../../CommonComponents/StepperModal";
import { getValidationSchema, steps } from "../BrandDNA/conts";
import BrandVoice from "../BrandDNA/components/BrandDNAForm/components/BrandVoice/BrandVoice";
import Competition from "../BrandDNA/components/BrandDNAForm/components/Competition/Competition";
import Business from "../BrandDNA/components/BrandDNAForm/components/Business";
import Persona from "../BrandDNA/components/BrandDNAForm/components/Persona";
import Global from "../BrandDNA/components/BrandDNAForm/components/Global";

import {
  GlobalFiltersProvider,
  useGlobalFilters,
} from "../../contexts/GlobalFiltersContext";
import AEOCharts from "./components/AEOCharts/AEOCharts";
import { useTheme } from "@emotion/react";
import RiskAuditCard from "../OptimizationHub/pages/Overview/components/RiskAuditCard/RiskAuditCard";
import TopicRanking from "../OptimizationHub/pages/Overview/components/TopicRanking";
import PageLayout from "../OptimizationHub/components/PageLayout/PageLayout";

const Dashboard = () => {
  const { brandId: id } = useParams();
  const brandId = id || localStorage.getItem("brandId");
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const [error, setError] = useState(null);
  const [isDnaCompleted, setIsDnaCompleted] = useState(false);
  const [openBlogDrawer, setOpenBlogDrawer] = useState(false);
  const [openDNAModal, setOpenDNAModal] = useState(false);
  const userBrands = JSON.parse(localStorage.getItem("loggedInUser"))?.brands;
  const [isAutoSave, setIsAutoSave] = useState(false);
  const [brandDetails, setBrandDetails] = useState({});
  const [featureFlags, setFeatureFlags] = useState();
  const {
    date,
    setDate,
    llm,
    setLlm,
    setInitialDate,
    setNoData: setGlobalNoData,
  } = useGlobalFilters();
  const [initialLoading, setInitialiLoading] = useState(false);

  const theme = useTheme();
  const companyName =
    localStorage.getItem("worskapceName") ||
    userBrands?.filter((brand) => parseInt(brand.id) === parseInt(brandId))[0]
      ?.name;
  useEffect(() => {
    setInitialiLoading(true);
    const url = `${API_URL}/aeo/optimization-date-range/${brandId}`;
    axios
      .get(url)
      .then((response) => {
        setInitialiLoading(false);
        const data = response.data;
        setDate([dayjs(data.start_date), dayjs(data.end_date)]);
        setInitialDate([dayjs(data.start_date), dayjs(data.end_date)]);
      })
      .catch((error) => {
        setInitialiLoading(false);
        if (error.response && error.response.data) {
          setDate([]);
          setInitialDate([]);
          setNoData(true);

          if (
            error.response.data.detail ===
            "Database error: 404: No evaluations found for the given brand ID"
          ) {
          } else {
            console.log("An error occurred:", error);
          }
        } else {
          console.log("An unexpected error occurred:", error);
        }
      });
  }, [brandId, setDate, setInitialDate]);
  useEffect(() => {
    axios
      .get(`${API_URL}/brands/${brandId}/overview`)
      .then((response) => {
        const workspaceData = response.data;
        const feature_flags = workspaceData.feature_flags;

        localStorage.setItem("workspace", JSON.stringify(workspaceData));
        const iscomplete = workspaceData.is_brand_dna_completed;
        setBrandDetails(workspaceData);
        setIsDnaCompleted(iscomplete);

        localStorage.setItem("feature_flags", JSON.stringify(feature_flags));
        setFeatureFlags(feature_flags);

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setError("Failed to fetch user data");
        setLoading(false);
      });
  }, [brandId]);

  const initialValues = useMemo(
    () => ({
      // business
      name: brandDetails?.name,
      primary_product: brandDetails?.primary_product,
      description: brandDetails?.description,
      conversion_goals: brandDetails?.conversion_goals || [],
      industry: brandDetails?.industry || "",
      business_model: brandDetails?.business_model || "",
      features: brandDetails?.features || ["", ""],
      benefits: brandDetails?.benefits || ["", ""],
      market_focus: brandDetails?.market_focus || "",
      usp: brandDetails.usp || "",
      country: brandDetails?.country || "",
      primary_language: brandDetails.primary_language || "",
      website: brandDetails?.website || "",
      additional_websites: brandDetails?.additional_websites || [""],
      secondary_countries: brandDetails?.secondary_countries || [],
      topics: brandDetails?.topics || [""],
      market_attributes: brandDetails?.market_attributes || [""],
      comparison_attributes: brandDetails?.comparison_attributes || [""],
      social_media_accounts_urls: brandDetails?.social_media_accounts_urls || [
        "",
      ],

      // persona
      age_ranges: brandDetails?.age_ranges || [],
      genders: brandDetails?.genders || [],
      interests: brandDetails?.interests || [],
      job_functions: brandDetails?.job_functions || [],
      job_titles: brandDetails?.job_titles || [],
      desires: brandDetails?.desires || ["", ""],
      pain_points: brandDetails?.pain_points || ["", ""],

      // brand voice
      brand_voice: brandDetails?.brand_voice || "",
      not_brand_voice: brandDetails?.not_brand_voice || "",
      formality_tone: brandDetails?.formality_tone || "",
      core_themes: brandDetails?.core_themes || [],
      emotions: brandDetails?.emotions || [],
      styles: brandDetails?.styles || [],

      // competitors
      competitors: brandDetails?.competitors || [{ name: "", website: "" }],
    }),
    // eslint-disable-next-line
    [brandId, brandDetails]
  );
  const handleSave = useCallback(
    (values) => {
      !isAutoSave && setLoading(true);
      axios
        .patch(`${API_URL}/brands/${brandId}`, values)
        .then((response) => {
          if (!isAutoSave) {
            setLoading(false);
            window.location.href = `/dashboard/${brandId}/`;
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    },
    [brandId, isAutoSave]
  );

  const getStepContent = useCallback((step) => {
    switch (step) {
      case 0:
        return <Business brandDetails={{}} />;
      case 1:
        return <Persona brandDetails={{}} />;

      case 2:
        return <BrandVoice brandDetails={{}} />;
      case 3:
        return <Competition brandDetails={{}} />;
      case 4:
        return <Global brandDetails={{}} />;
      default:
        return <Business brandDetails={{}} />;
    }
  }, []);

  useEffect(() => {
    if (!isDnaCompleted && !loading) setOpenDNAModal(true);
    else setOpenDNAModal(false);
  }, [isDnaCompleted, loading]);

  return loading ? (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "60%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        style={{
          width: 20,
          height: 20,
          color: "#2D333A",
        }}
      />
    </div>
  ) : (
    <PageTemplate>
      <Typography
        style={{
          fontSize: "37.01px",
          fontWeight: 500,
          margin: 0,
          padding: 0,
          lineHeight: 1,
        }}
        color="text.primary"
      >
        Hey, I'm Goodie.
      </Typography>
      <Typography
        style={{
          fontSize: "37.01px",
          fontWeight: 500,
          marginBottom: 50,
          margin: 0,
          padding: 0,
        }}
        color="text.tertiary"
      >
        Your supercharged, AI-powered marketing partner.{" "}
      </Typography>

      {!isDnaCompleted && <IncompleteDna />}
      {featureFlags?.can_access_aeo && (
        <>
          <Divider
            sx={{
              height: "0.5px",
              mt: 5,
              mb: 3,
              borderColor: "border.tertiary",
            }}
          />
          <Typography
            variant="h4"
            style={{
              fontWeight: 500,
              color: theme.palette.text.tertiary,
              fontSize: 14,
              marginBottom: 15,
            }}
          >
            AI Visibility
          </Typography>
          <GlobalFiltersProvider>
            <AEOCharts />
          </GlobalFiltersProvider>
        </>
      )}
      {featureFlags?.can_access_optimization_hub && (
        <>
          <Divider
            sx={{
              height: "0.5px",
              mt: 5,
              mb: 3,
              borderColor: "border.tertiary",
            }}
          />
          <Typography
            variant="h4"
            style={{
              fontWeight: 500,
              color: theme.palette.text.tertiary,
              fontSize: 14,
              marginBottom: 15,
            }}
          >
            AI Optimization
          </Typography>
          <Grid
            container
            direction="row"
            sx={{
              mt: 4,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item xs={3.8}>
              <TopicRanking />
            </Grid>
            <Grid item xs={7.9}>
              <RiskAuditCard />
            </Grid>
          </Grid>
        </>
      )}

      {featureFlags?.can_access_content_writer && (
        <RecentSection isDashboardView />
      )}

      <GenerateBlogModal
        openBlogDrawer={openBlogDrawer}
        setOpenBlogDrawer={setOpenBlogDrawer}
        brandId={brandId}
      />
      <StepperModal
        open={openDNAModal}
        handleClose={() => setOpenDNAModal(false)}
        setOpenModal={setOpenDNAModal}
        modalName="Brand DNA"
        title={companyName}
        steps={steps}
        initialStep={0}
        getStepContent={getStepContent}
        formikChildren={(Business, Persona, BrandVoice, Competition)}
        initialValues={initialValues}
        getValidationSchema={getValidationSchema}
        handleSave={handleSave}
        loading={loading}
        setIsAutoSave={setIsAutoSave}
        isAutoSave={isAutoSave}
      />
    </PageTemplate>
  );
};
export default Dashboard;
