import { Box, Typography } from "@mui/material";
import axios from "axios";
import { useFormikContext } from "formik";
import { useCallback, useEffect, useState } from "react";
import { API_URL, ML_API_URL } from "../../../../api";
import SingleCheckBox from "../../../../CommonComponents/FormField/components/SingleCheckBox/SingleCheckBox";
import FormField from "../../../../CommonComponents/FormField/FormField";
import BlogWriterCard from "../Card/Card";
import InputSection from "./components/InputSection";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const wordCountOptions = [
  { label: "Short (800-1500)", value: "800-1500" },
  { label: "Medium (1500-2200)", value: "1500-2200" },
  { label: "Long (2200-3000)", value: "2200-3000" },
];

const Configuration = ({
  nextFunction,
  setGeneratedTitles,
  setLoadingAnimation,
  setLoadingAnimationText,
}) => {
  const { values } = useFormikContext();
  const [has_author_stamp, setAuthorStamp] = useState(false);

  const handleNext = useCallback(
    (step, setNextStep) => {
      setLoadingAnimation(true);
      setLoadingAnimationText("Generating relevant titles.");
      axios
        .post(`${ML_API_URL}/createai/generatetitles`, values)
        .then((response) => {
          setLoadingAnimation(false);
          setGeneratedTitles(response.data.titles);
          setNextStep(step + 1);
        })
        .catch((err) => {
          setLoadingAnimation(false);
        });
    },
    [values, setGeneratedTitles]
  );

  useEffect(() => {
    nextFunction(handleNext);
  }, [nextFunction, values, handleNext]);

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem("loggedInUser"))?.id;
    axios
      .get(`${API_URL}/accounts/${userId}`)
      .then((response) => {
        const userData = response.data;
        setAuthorStamp(userData.has_author_stamp);
      })
      .catch((error) => {});
  }, []);

  return (
    <BlogWriterCard title="Configure the Blog" width={900} marginTop={-20}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: 20,
          marginTop: 5,
          marginBottom: 20,
        }}
      >
        <Typography
          style={{ fontSize: 14, maxWidth: 597 }}
          color="text.secondary"
        >
          Configure the specifics of your blog post to tailor it to your needs.
          Use the following options: * Required fields
        </Typography>

        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: 30,
            marginTop: 30,
          }}
        >
          <InputSection title="Article length" required description="">
            <FormField
              name="config.wordCount"
              type="select"
              customizedStyling={{
                width: "40%",
                borderRadius: "11px",
              }}
              options={wordCountOptions}
            />
          </InputSection>
          <InputSection
            title="Primary Keyword"
            required
            description="Enter the main keyword for SEO purposes."
          >
            {" "}
            <FormField
              name="config.primaryKeyword"
              type="input"
              customizedStyling={{ width: "100%" }}
              placeholder="Growth"
            />
          </InputSection>{" "}
          <InputSection
            title="Secondary Keywords"
            required
            description="Add additional keywords to enhance SEO. Please separate words with commas."
          >
            {" "}
            <FormField
              name="config.secondaryKeyword"
              type="input"
              customizedStyling={{ width: "100%" }}
              placeholder="Growth, Analytics, C-Suite"
            />
          </InputSection>{" "}
          <div style={{ display: "flex", flexDirection: "column", rowGap: 10 }}>
            <SingleCheckBox
              name="author_stamp_flag"
              header="Utilize Author Stamp Feature: "
              subHeader="Reference your personal writing style for this blog."
              disabled={!has_author_stamp}
            />
            {!has_author_stamp && (
              <Typography
                variant="h4"
                sx={{
                  padding: "6px",
                  fontWeight: 400,
                  fontSize: 14,
                  display: "inline-flex",
                  alignItems: "center",
                  flexDirection: "row",
                  columnGap: 0.5,
                  width: "fit-content",
                }}
                color="text.secondary"
              >
                <LockOutlinedIcon style={{ fontSize: 18 }} />
                To unlock this feature, complete the Author Stamp in your
                account settings.{" "}
              </Typography>
            )}
          </div>
          <SingleCheckBox
            name="generate_FAQs_flag"
            header="Generate FAQs:"
            subHeader="Address common questions at the end of your article."
          />{" "}
          <SingleCheckBox
            name="generate_citation_flag"
            header="Add citations:"
            subHeader="Add sources as links of footnotes"
          />
        </Box>
      </Box>
    </BlogWriterCard>
  );
};
export default Configuration;
