import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbDownOffAltOutlinedIcon from "@mui/icons-material/ThumbDownOffAltOutlined";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { API_URL, ML_AEO_URL } from "../../../../../../api";
import RecommendationsSection from "../RecommendationsSection/RecommendationsSection";
import StatusDropdown from "../StatusDropDown";
const isEmpty = (obj) => Object.keys(obj).length === 0;

const Opportunity = ({ selectedRow, subTab }) => {
  const { id } = selectedRow;
  const [showStatusReminder, setShowStatusReminder] = useState(false);
  const [loading, setLoading] = useState();
  const [data, setData] = useState({});
  const [feedbackLoading, setFeedbackLoading] = useState();
  const [status, setStatus] = useState(selectedRow?.source_status || "Inbox");
  const [isGenerated, setIsGenerated] = useState();
  const [showFeedback, setShowFeedback] = useState();

  const refetch = useCallback(() => {
    setShowFeedback(false);
    setIsGenerated(false);
    setLoading(true);
    id
      ? axios
          .get(`${API_URL}/aeo/references/${id}`)
          .then((response) => {
            setLoading(false);
            setData(response.data);
            setStatus(response.data.source_status);
            setShowFeedback(
              !response.data.feedback && !isEmpty(response.data.optimization)
            );
          })
          .catch((err) => {
            setLoading(false);
          })
      : setData({});
  }, [id]);

  useEffect(() => {
    refetch();
  }, [id, refetch]);

  useEffect(() => {
    setShowStatusReminder(false);
  }, [data, subTab]);

  const handleGenerateClick = useCallback(() => {
    setLoading(true);
    axios
      .post(`${ML_AEO_URL}/aeo/optimization-generation`, {
        sourceId: id,
      })
      .then((response) => {
        setIsGenerated(true);
        setData(response.data);
        setShowFeedback(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("test error", err);
      });
    setShowStatusReminder(true);
  }, [id]);

  const handleCloseStatusReminder = () => {
    setShowStatusReminder(false);
  };

  const handleFeedbackClick = useCallback(
    (feedback) => {
      setFeedbackLoading(true);
      axios
        .patch(`${API_URL}/aeo/references/${id}`, { feedback })
        .then(() => {
          setFeedbackLoading(false);
          setShowFeedback(false);
        })
        .catch((err) => {
          setFeedbackLoading(false);
          console.error("Error updating feedback:", err);
        });
    },
    [id]
  );

  useEffect(() => {
    setStatus(selectedRow.source_status);
  }, [selectedRow]);

  useEffect(() => {
    axios
      .patch(`${API_URL}/aeo/references/${id}`, { source_status: status })
      .then((response) => {})
      .catch((err) => {
        console.error("Failed to update the item:", err);
      });
  }, [status]);
  if (!data) {
    return (
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "text.secondary",
          borderRadius: "10px",
          padding: "16px",
          backgroundColor: "background.secondary",
        }}
      >
        No data selected.
      </Box>
    );
  }
  return (
    <Box
      sx={{
        flex: 1,
        padding: "20px",
        backgroundColor: "background.secondary",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        mt: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontSize: "12px",
            lineHeight: "14px",
            color: "text.primary",
            wordBreak: "break-word",
            flex: 1,
          }}
        >
          {data.source_url}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              color: "text.secondary",
              textTransform: "capitalize",
              fontSize: "12px",
              lineHeight: "14px",
            }}
          >
            Status
          </Typography>

          <StatusDropdown
            selectedStatus={status}
            setSelectedStatus={setStatus}
          />
        </Box>
      </Box>
      {showStatusReminder && (
        <Box
          sx={{
            backgroundColor: "background.brand20",
            padding: "8px 16px",
            borderRadius: "20px",
            mb: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "text.brandbold",
              fontSize: "12px",
              lineHeight: "14px",
            }}
          >
            To keep track of your recommendations, change the status in the top
            right.
          </Typography>
          <IconButton
            size="small"
            onClick={handleCloseStatusReminder}
            sx={{ color: "text.primary", padding: 0 }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
      <Box sx={{ overflowY: "auto", height: "400px" }}>
        <Box
          sx={{
            backgroundColor: "background.tertiary",
            padding: "10px",
            borderRadius: "10px",
            mb: "10px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: 500,
              color: "text.primary",
              fontSize: "16px",
              lineHeight: "18px",
            }}
          >
            Opportunity
          </Typography>
        </Box>
        <Typography
          variant="body2"
          sx={{
            color: "text.tertiary",
            marginBottom: "10px",
            fontSize: "14px",
            lineHeight: "20px",
          }}
        >
          {data.recommendations}
        </Typography>

        <RecommendationsSection
          data={isGenerated ? data : data.optimization}
          loading={loading}
          isEarned={selectedRow?.type?.toLowerCase() === "earned"}
        />

        {!loading && showFeedback && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "8px 16px",
              borderRadius: "29px",
              backgroundColor: "background.tertiary",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Typography
                variant="body2"
                sx={{
                  color: "text.tertiary",
                  fontSize: "12px",
                  lineHeight: "14px",
                }}
              >
                Was this recommendation helpful?
              </Typography>
              {!feedbackLoading ? (
                <>
                  <ThumbUpOutlinedIcon
                    sx={{
                      color: "text.tertiary",
                      cursor: "pointer",
                      fontSize: "14px",
                    }}
                    onClick={() => handleFeedbackClick("positive")}
                  />
                  <ThumbDownOffAltOutlinedIcon
                    sx={{
                      color: "text.tertiary",
                      cursor: "pointer",
                      fontSize: "14px",
                    }}
                    onClick={() => handleFeedbackClick("negative")}
                  />{" "}
                </>
              ) : (
                <CircularProgress
                  style={{
                    width: 20,
                    height: 20,
                    color: "#2D333A",
                  }}
                />
              )}
            </Box>
            <IconButton
              size="small"
              sx={{ color: "text.primary", padding: 0 }}
              onClick={() => setShowFeedback(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
        {!loading && !(isGenerated ? data : data.optimization) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: "auto",
              backgroundColor: "background.brand20",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: "text.brand",
                fontSize: "16px",
                lineHeight: "18px",
                fontWeight: 500,
              }}
            >
              Optimization Recommendation
            </Typography>

            <Button
              variant="contained"
              endIcon={<ArrowRightAltIcon />}
              sx={{
                backgroundColor: "background.brand",
                color: "text.chiptext",
                padding: "6px 15px",
                borderRadius: "100px",
                textTransform: "none",
                boxShadow: "none",
                "&:hover": {
                  backgroundColor: "background.brand",
                },
                "& .css-9tj150-MuiButton-endIcon": { ml: "4px" },
              }}
              onClick={handleGenerateClick}
            >
              Generate
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Opportunity;
