export const API_STATUSES = {
  INBOX: "Inbox",
  IN_PROGRESS: "Inprogress",
  COMPLETED: "Completed",
  ARCHIVED: "Archived",
};

export const DISPLAY_STATUSES = {
  [API_STATUSES.INBOX]: "Inbox",
  [API_STATUSES.IN_PROGRESS]: "In Progress",
  [API_STATUSES.COMPLETED]: "Completed",
  [API_STATUSES.ARCHIVED]: "Archived",
};

export const getStatusColor = (status, theme) => {
  const colors = {
    [API_STATUSES.INBOX]: theme.palette.text.brand,
    [API_STATUSES.IN_PROGRESS]: theme.palette.text.warning,
    [API_STATUSES.COMPLETED]: theme.palette.text.positive,
    [API_STATUSES.ARCHIVED]: theme.palette.text.archive,
  };
  return colors[status] || theme.palette.text.primary;
};
