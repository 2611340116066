import ListDisplay from "../../ListDisplay";

const TechnicalPerformance = ({ data, mapSections }) => {
  return (
    <ListDisplay
      data={data}
      mapSections={mapSections}
      excludedKeys={["load_time_optimization", "crawl_budget_recommendations"]}
      extraFields={[
        {
          key: "load_time_optimization",
          label: "Load Time Optimization",
          isBold: true,
        },
        {
          key: "crawl_budget_recommendations",
          label: "Crawl Budget Allocation",
          isBold: true,
        },
      ]}
    />
  );
};
export default TechnicalPerformance;
