import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { ML_AEO_URL } from "../../../../../../api";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material";
import { useGlobalFilters } from "../../../../../../contexts/GlobalFiltersContext";
import { dummyLineData } from "../../../../../AEO/consts/consts";
import BrandsList from "../../../../../AEO/components/BrandsList";
import {
  reorderKeys,
  transformCompetitorsLineChartData,
} from "../../../../../AEO/utils/DataUtils";
import ChartCard from "../../../../../AEO/components/ChartCard/ChartCard";
import LineChart from "../../../../../../CommonComponents/Charts/LineChart";

const TopicVisbilityLineChart = ({
  hasBrandLabelOnly,
  isGlobal,
  isMainSelected,
}) => {
  const {
    date: globalDate,
    llm,
    initialDate: globalInitialDate,
    selectedCountries,
  } = useGlobalFilters();
  const [selectedLLM, setSelectedLLM] = useState(llm);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const { brandId } = useParams();
  const [data, setData] = useState([]);
  const currentWorkspaceName = localStorage.getItem("worskapceName");
  const [initialBrands, setInititalBrands] = useState([]);
  const [date, setDate] = useState(globalDate);
  const [initialDate, setInitialDate] = useState(globalInitialDate);
  const { noData } = useGlobalFilters();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = (url, values) => {
      setLoading(true);
      axios
        .post(url, values)
        .then((response) => {
          const data = response.data;

          const filteredData = transformCompetitorsLineChartData(
            data,
            selectedLLM[0]
          );

          setData(filteredData.transformedData);
          setInititalBrands(filteredData?.keys);

          setSelectedBrands(filteredData?.keys);
        })
        .catch((error) => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    if (date.length > 0 && date[0] && date[1]) {
      const values = {
        brandId,
        start_date: date[0].format("YYYY-MM-DD"),
        end_date: date[1].format("YYYY-MM-DD"),
        LLMs: selectedLLM,
        comparsion_type: "Topics",
      };

      fetchData(`${ML_AEO_URL}/aeo/visibility-performance-line-chart`, values);
    }
  }, [brandId, selectedLLM, date, isGlobal, selectedCountries]);

  useEffect(() => {
    setSelectedLLM(llm);
  }, [llm]);

  useEffect(() => {
    setDate(globalDate);
  }, [globalDate]);

  useEffect(() => {
    setInitialDate(globalInitialDate);
  }, [globalInitialDate]);
  const theme = useTheme();

  const colorMap = useMemo(() => {
    const colors = [
      theme.palette.brands.A,
      theme.palette.brands.B,
      theme.palette.brands.C,
      theme.palette.brands.D,
      theme.palette.brands.E,
      theme.palette.brands.F,
      theme.palette.brands.G,
    ];
    const brands = reorderKeys(initialBrands, currentWorkspaceName);
    const map = {};
    brands.forEach((brand, index) => {
      map[brand] = colors[index % colors.length];
    });
    return map;
  }, [initialBrands]);
  return (
    <ChartCard
      title="Topic Visibility"
      tooltipMessagePrimary="Measures your brand’s prominence within defined search topics by LLM. Higher scores indicate stronger visibility."
      tooltipMessageSecondary="Scores are out of 100."
      hasCalendarSelect
      hasLLMselect
      height={"100%"}
      selectedLLM={selectedLLM}
      setSelectedLLM={setSelectedLLM}
      setDate={setDate}
      date={date}
      initialDate={initialDate}
      isLoading={loading}
      isError={error}
    >
      <LineChart
        data={noData ? dummyLineData : data}
        xaxisDatakey="xAxis"
        hasXasix
        hasYaxis
        height={200}
        hasTooltip
        marginTop={20}
        marginLeft={-20}
        hasGrid
        mainArea={noData ? "NoGood" : currentWorkspaceName}
        secondaryLinesDataKeys={selectedBrands.filter(
          (key) => key !== currentWorkspaceName
        )}
        isMainSelected={
          isMainSelected ||
          hasBrandLabelOnly ||
          selectedBrands.filter((key) => key === currentWorkspaceName).length >
            0
        }
        colorMap={colorMap}
      />

      <BrandsList
        brands={reorderKeys(initialBrands, currentWorkspaceName)}
        selectedBrands={selectedBrands}
        setSelectedBrands={setSelectedBrands}
        colorMap={colorMap}
      />
    </ChartCard>
  );
};
export default TopicVisbilityLineChart;
