import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import SentimentSatisfiedAltSharpIcon from "@mui/icons-material/SentimentSatisfiedAltSharp";
import SentimentDissatisfiedSharpIcon from "@mui/icons-material/SentimentDissatisfiedSharp";
import CheckCircleOutlineSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const normalizeScore = (score) =>
  typeof score === "string" ? score.toLowerCase() : score;

const getColors = (score, theme) => {
  const normalizedScore = normalizeScore(score);

  if (
    normalizedScore >= 80 ||
    normalizedScore === "yes" ||
    normalizedScore === "positive"
  ) {
    return [theme.palette.background.positive, theme.palette.text.positive];
  } else if (normalizedScore >= 50 || normalizedScore === "neutral") {
    return [theme.palette.background.neutral, theme.palette.text.neutral];
  } else if (
    !!normalizedScore &&
    (normalizedScore < 50 ||
      normalizedScore === "negative" ||
      normalizedScore === "no")
  ) {
    return [theme.palette.background.negative, theme.palette.text.negative];
  } else {
    return [theme.palette.background.brand20, theme.palette.text.brandbold];
  }
};

const getIcon = (score, iconType) => {
  const normalizedScore = normalizeScore(score);
  if (normalizedScore === "") return null;
  const isPositive =
    normalizedScore >= 80 ||
    normalizedScore === "yes" ||
    normalizedScore === "positive";
  const isNeutral = normalizedScore >= 50 || normalizedScore === "neutral";
  const isNegative = normalizedScore < 50 || normalizedScore === "negative";

  if (iconType === "check") {
    return isPositive || isNeutral ? (
      <CheckCircleOutlineSharpIcon fontSize="18px" />
    ) : (
      <CancelOutlinedIcon fontSize="18px" />
    );
  }

  if (isPositive) return <SentimentSatisfiedAltSharpIcon fontSize="18px" />;
  if (isNeutral) return <SentimentSatisfiedAltSharpIcon fontSize="18px" />;
  if (isNegative) return <SentimentDissatisfiedSharpIcon fontSize="18px" />;

  return null;
};

const ScoreTypography = ({
  score,
  iconType,
  fontSize = 15,
  fontWeight = 600,
}) => {
  const theme = useTheme();
  const normalizedScore = normalizeScore(score);

  return (
    <Typography
      style={{
        maxWidth: "fit-content",
        borderRadius: 11.65,
        display: "flex",
        flexDirection: "row",
        columnGap: 5,
        fontSize,
        fontWeight,
        padding: "3px 7px",
        alignItems: "center",
        backgroundColor: getColors(normalizedScore, theme)[0],
        color: getColors(normalizedScore, theme)[1],
      }}
    >
      {iconType && getIcon(normalizedScore, iconType)}{" "}
      {score ? score : "Not Recognized"}
    </Typography>
  );
};

export default ScoreTypography;
