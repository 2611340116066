import { MenuItem, Select, useTheme, Box, Typography } from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import {
  API_STATUSES,
  DISPLAY_STATUSES,
  getStatusColor,
} from "../../../../../../utils/sourceStatus";

const StatusDropdown = ({ selectedStatus, setSelectedStatus }) => {
  const statuses = Object.values(API_STATUSES);
  const theme = useTheme();

  return (
    <Select
      variant="standard"
      disableUnderline
      value={selectedStatus}
      onChange={(e) => setSelectedStatus(e.target.value)}
      IconComponent={ExpandMoreOutlinedIcon}
      MenuProps={{
        PaperProps: {
          sx: {
            margin: 0,
            padding: 0,
            backgroundColor: "background.secondary",
            borderRadius: "10px",
            boxShadow: "none",
            "& .MuiMenuItem-root": {
              color: "text.primary",
              "&:hover": {
                backgroundColor: "dropdown.hover",
              },
              "&.Mui-selected": {
                backgroundColor: "dropdown.selected",
              },
            },
          },
        },
      }}
      sx={{
        "& .MuiSelect-select": {
          display: "flex",
          flexDirection: "row",
          columnGap: 1,
          alignItems: "center",
          border: "1px solid",
          borderColor: "border.secondary",
          borderRadius: "50px",
          padding: "4px 10px",
        },
        "& .MuiSvgIcon-root": {
          color: "text.primary",
        },
      }}
    >
      {statuses.map((status) => (
        <MenuItem
          key={status}
          value={status}
          sx={{
            fontSize: "14px",
            color: "text.primary",
            display: "flex",
            flexDirection: "row",
            columnGap: 1,
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "6px",
              height: "6px",
              borderRadius: "50%",
              backgroundColor: getStatusColor(status, theme),
              flexShrink: 0,
            }}
          ></Box>
          <Typography
            variant="body2"
            sx={{
              fontSize: "12px",
              lineHeight: "14px",
              color: "text.primary",
              textTransform: "capitalize",
            }}
          >
            {DISPLAY_STATUSES[status]}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  );
};

export default StatusDropdown;
