import ListDisplay from "../../ListDisplay";

const Freshness = ({ data, mapSections }) => {
  return (
    <ListDisplay
      data={data}
      mapSections={mapSections}
      excludedKeys={["priority"]}
      extraFields={[
        { key: "temporal_relevance", label: "Timeliness", isBold: true },
        { key: "recency_optimizations.priority", label: "Priority", isBold: true },
      ]}
      nestedKey="recency_optimizations"
    />
  );
};

export default Freshness;
