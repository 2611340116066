import React from "react";
import { useTheme } from "@mui/material";

const SearchIcon = ({ color, width = "16px", height = "16px" }) => {
  const theme = useTheme();

  const Color = theme.palette.text.tertiary || color || "#AFB1B7";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        ill={Color}
        d="M11.6642 10.8357L9.50007 8.68899C10.3401 7.64156 10.7469 6.31208 10.6369 4.97391C10.5268 3.63575 9.90821 2.39061 8.90829 1.49453C7.90837 0.598451 6.60312 0.119536 5.26094 0.156263C3.91876 0.19299 2.64165 0.742567 1.69223 1.69199C0.742811 2.64141 0.193234 3.91851 0.156507 5.26069C0.119781 6.60288 0.598695 7.90812 1.49478 8.90804C2.39086 9.90796 3.63599 10.5266 4.97416 10.6366C6.31232 10.7467 7.64181 10.3399 8.68923 9.49982L10.8359 11.6465C10.8901 11.7012 10.9546 11.7446 11.0257 11.7742C11.0968 11.8038 11.1731 11.819 11.2501 11.819C11.3271 11.819 11.4033 11.8038 11.4744 11.7742C11.5455 11.7446 11.61 11.7012 11.6642 11.6465C11.7694 11.5377 11.8281 11.3924 11.8281 11.2411C11.8281 11.0898 11.7694 10.9444 11.6642 10.8357ZM5.41673 9.49982C4.60913 9.49982 3.81966 9.26034 3.14815 8.81166C2.47665 8.36297 1.95328 7.72524 1.64422 6.97911C1.33517 6.23298 1.2543 5.41196 1.41186 4.61987C1.56942 3.82778 1.95832 3.1002 2.52938 2.52914C3.10045 1.95807 3.82803 1.56917 4.62011 1.41162C5.4122 1.25406 6.23323 1.33492 6.97936 1.64398C7.72549 1.95304 8.36322 2.47641 8.8119 3.14791C9.26058 3.81941 9.50007 4.60888 9.50007 5.41649C9.50007 6.49946 9.06986 7.53807 8.30409 8.30384C7.53831 9.06961 6.4997 9.49982 5.41673 9.49982Z"
        fill="#666666"
      />
    </svg>
  );
};

export default SearchIcon;
