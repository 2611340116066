import { MenuItem, Select, useTheme } from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { useGlobalFilters } from "../../../../contexts/GlobalFiltersContext";

const CustomDropDown = ({ options }) => {
  const theme = useTheme();
  const { selectedCustomFilter, setSelectedCustomFilter } = useGlobalFilters();
  return (
    <Select
      variant="standard"
      disableUnderline
      value={selectedCustomFilter}
      onChange={(e) => setSelectedCustomFilter(e.target.value)}
      IconComponent={ExpandMoreOutlinedIcon}
      sx={{
        "& .MuiSelect-select": {
          padding: "4px 8px",
          borderRadius: "11px",
          backgroundColor: theme.palette.background.tertiary,
          fontSize: "14px",
          color: "text.primary",
          display: "flex",
          flexDirection: "row",
          columnGap: 0.5,
          alignItems: "center",
          fontWeight: 400,
        },
        "& .MuiSvgIcon-root": {
          fontSize: 14,
          color: "text.primary",
          marginRight: "5px",
        },
      }}
      MenuProps={{
        PaperProps: {
          sx: {
            backgroundColor: "background.secondary",
            borderRadius: "10px",
            boxShadow: "none",
            "& .MuiMenuItem-root": {
              color: "text.primary",
              "&:hover": {
                backgroundColor: "dropdown.hover",
              },
              "&.Mui-selected": {
                backgroundColor: "dropdown.selected",
              },
            },
          },
        },
      }}
    >
      {options.map((option) => (
        <MenuItem
          value={option}
          sx={{
            fontSize: "14px",
            color: "#000",
            display: "flex",
            flexDirection: "row",
            columnGap: 0.5,
            alignItems: "center",
          }}
        >
          {option}
        </MenuItem>
      ))}
    </Select>
  );
};
export default CustomDropDown;
