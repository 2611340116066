import { Box } from "@mui/material";
import React from "react";
import ScoreGrid from "../../pages/AEOAssessmentReport/components/SentimentAnalysis/Components/ScoreGrid";
import MultipleValuesPercentageWheel from "../MultipleValuesPercentageWheel";

const MultipleValuesWheelGrid = ({
  wheelColors,
  data,
  wheelCenterText,
  wheelWidth,
  wheelHeight,
  marginTop = "20px",
  wheelInnerRaduis,
  wheelOuterRaduis,
  justifyContent = "flex-start",
}) => {
  const COLORS = wheelColors;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        columnGap: { xs: "15px", sm: "40px" },
        alignItems: "center",
        justifyContent: justifyContent,
        marginTop: marginTop,
      }}
    >
      <MultipleValuesPercentageWheel
        wheelCenterText={wheelCenterText}
        data={data}
        wheelColors={COLORS}
        width={wheelWidth}
        height={wheelHeight}
        wheelInnerRaduis={wheelInnerRaduis}
        wheelOuterRaduis={wheelOuterRaduis}
      />
      <Box style={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
        {data?.map((item, index) => (
          <ScoreGrid
            value={item.value > 0 ? item?.value?.toFixed(0) : item.value}
            color={COLORS[index]}
            label={item.label}
          />
        ))}
      </Box>
    </Box>
  );
};

export default MultipleValuesWheelGrid;
