import { Divider, Grid, Typography, useTheme } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ML_AEO_URL } from "../../../../../../api";
import TopicRecognitionIcon from "../../../../../../CommonComponents/customIcons/TopicRecognitionIcon";
import { useGlobalFilters } from "../../../../../../contexts/GlobalFiltersContext";
import ChartCard from "../../../../../AEO/components/ChartCard/ChartCard";
import { LLMs, mapLLmLabels } from "../../../../../AEO/utils/DataUtils";
import ScoreTypography from "../../../../../AEOAssessmentReport/components/EntityRecognitionScore/components/ScoreTypography/ScoreTypography";

const dummyData = [
  { llm: "ChatGpt", recognized: "Yes", sentiment: "Positive" },
  { llm: "Perplexity", recognized: "No", sentiment: "Neutral" },
  { llm: "Gemini", recognized: "Yes", sentiment: "Negative" },
];
const TopicBrandRecognition = () => {
  const theme = useTheme();
  const { noData } = useGlobalFilters();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(noData ? dummyData : []);
  const { brandId, topicName } = useParams();
  const name = topicName.replace(/-/g, " ");

  useEffect(() => {
    const values = {
      brandId,
      topic: name,
    };
    setLoading(true);
    axios
      .post(`${ML_AEO_URL}/aeo/brand-entity-recognition`, values)
      .then((response) => {
        setLoading(false);
        setData(response.data);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
      });
  }, [brandId, name]);
  return (
    <ChartCard
      title="Topic Brand Recognition"
      tooltipMessagePrimary="Indicates your brand’s position in LLM results for the search topic, confirms entity recognition by each LLM, and reports overall sentiment (positive, neutral, or negative) towards you brand within the search topic."
      height="100%"
      icon={<TopicRecognitionIcon color={theme.palette.text.primary} />}
      isLoading={loading}
      isError={error}
    >
      <Grid container width={"100%"} sx={{ marginTop: 2 }}>
        <Grid item xs={1}>
          <Typography
            color="text.tertiary"
            style={{ fontWeight: 400, fontSize: 12 }}
          >
            Rank
          </Typography>
        </Grid>

        {["LLM", "Recognized?", "Sentiment"].map((title, index) => (
          <Grid
            item
            xs={3.6}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Typography
              color="text.tertiary"
              style={{ fontWeight: 400, fontSize: 12 }}
            >
              {title}
            </Typography>
          </Grid>
        ))}
      </Grid>

      <Divider
        sx={{
          borderColor: theme.palette.border.quaternary,
          width: "100%",
          margin: "10px 0px",
        }}
      />

      {data.map((item, index) => (
        <Grid container width="100%" justifyContent="space-between">
          <Grid
            item
            xs={1}
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <Typography
              color="text.secondary"
              style={{
                fontWeight: 600,
                fontSize: 16,
                backgroundColor: theme.palette.background.tertiary,
                borderRadius: 11.65,
                padding: "2px",
                width: 33,
                display: "flex",
                justifyContent: "center",
              }}
            >
              {index + 1}
            </Typography>
          </Grid>

          <Grid
            item
            xs={3.6}
            style={{
              display: "flex",
              flexDirection: "row",
              columnGap: 5,
            }}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <img
              src={LLMs[item.llm_name]}
              style={{ width: 14, height: 14 }}
              alt={item.llm_name}
            />
            <Typography
              color="text.primary"
              style={{ fontWeight: 400, fontSize: 14.55 }}
            >
              {mapLLmLabels[item.llm_name]}
            </Typography>
          </Grid>
          <Grid
            item
            xs={3.6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <ScoreTypography
              iconType="check"
              score={item.recognized ? "Yes" : "No"}
            />
          </Grid>
          <Grid
            item
            xs={3.6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <ScoreTypography iconType="face" score={item.sentiment} />
          </Grid>
          {index < data.length - 1 && (
            <Divider
              sx={{
                borderColor: theme.palette.border.quaternary,
                width: "100%",
                margin: "5px 0px",
              }}
            />
          )}
        </Grid>
      ))}
    </ChartCard>
  );
};
export default TopicBrandRecognition;
