import ListDisplay from "../../ListDisplay";

const Freshness = ({ data, mapSections }) => {
  return (
    <ListDisplay
      data={data}
      mapSections={mapSections}
      excludedKeys={["priority"]}
      extraFields={[
        {
          key: "readability_optimizations.priority",
          label: "Priority",
          isBold: true,
        },
      ]}
      nestedKey="readability_optimizations"
    />
  );
};

export default Freshness;
