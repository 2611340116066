import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  useTheme,
} from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../../api";
import { useGlobalFilters } from "../../../../contexts/GlobalFiltersContext";

const CountriesDropdown = ({ backgroundColorValue }) => {
  const theme = useTheme();
  const BgColor = theme.palette.background[backgroundColorValue];
  const { brandId } = useParams();
  const [open, setOpen] = useState(false);
  const { setInitialCountries, initialCountries, setSelectedCountries } =
    useGlobalFilters();

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [unselectedOptions, setUnselectedOptions] = useState([]);

  useEffect(() => {
    setUnselectedOptions(
      initialCountries.filter((country) => !selectedOptions.includes(country))
    );
  }, [selectedOptions]);

  const handleChange = (event) => {
    setSelectedOptions(event.target.value);
    setSelectedCountries(event.target.value);
  };

  useEffect(() => {
    axios
      .get(`${API_URL}/brands/${brandId}/countries/`)
      .then((response) => {
        const data = response.data;

        const countries = [
          data?.country,
          ...(data?.secondary_countries?.length
            ? data.secondary_countries
            : []),
        ];
        setSelectedOptions(countries);
        setInitialCountries(countries);
        setSelectedCountries(countries);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, [brandId, setInitialCountries]);

  return (
    <FormControl>
      <Select
        multiple
        value={selectedOptions}
        onChange={handleChange}
        renderValue={(selected) => selected.join(", ")}
        size="small"
        variant="standard"
        disableUnderline
        IconComponent={ExpandMoreOutlinedIcon}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        sx={{
          "& .MuiSelect-select": {
            borderRadius: "11px",
            padding: "4px 8px",
            backgroundColor: BgColor,
            fontSize: "14px",
            color: "text.tertiary",
            display: "flex",
            flexDirection: "row",
            columnGap: 0.5,
            alignItems: "center",
            fontWeight: 400,
          },
          "& .MuiSvgIcon-root": {
            fontSize: 15,
            color: "text.tertiary",
            marginRight: "5px",
            borderRadius: "11px",
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 350,
              overflowY: "auto",
              backgroundColor: "background.secondary",
              borderRadius: "10px",
              boxShadow: "none",
              "& .MuiMenuItem-root": {
                color: "text.primary",
                "&:hover": {
                  backgroundColor: "dropdown.hover",
                },
                "&.Mui-selected": {
                  backgroundColor: "dropdown.selected",
                },
              },
            },
          },
        }}
      >
        {/* Display selected options first */}
        {selectedOptions.map((country) => (
          <MenuItem
            key={country}
            value={country}
            sx={{ fontSize: 14, color: "#000" }}
          >
            <Checkbox checked />
            <ListItemText primary={country} />
          </MenuItem>
        ))}

        {/* Display unselected options */}
        {unselectedOptions.map((country) => (
          <MenuItem key={country} value={country} sx={{ fontSize: 14 }}>
            <Checkbox checked={selectedOptions.includes(country)} />
            <ListItemText primary={country} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CountriesDropdown;
