import PromptCounter from "../../../../CommonComponents/PromptCounter";

const MentionCount = ({ loading, prompt }) => {
  return (
    <PromptCounter
      title={"Mention Count"}
      prompt={prompt}
      loading={loading}
      tooltipMessage={
        "The total number of times your brand is mentioned across all analyzed sources within the topic."
      }
    />
  );
};
export default MentionCount;
