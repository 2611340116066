import ListDisplay from "../../ListDisplay";

const LinkingStrategy = ({ data, mapSections }) => {
  return (
    <ListDisplay
      data={data}
      mapSections={mapSections}
      excludedKeys={["section", "link_improvement"]}
      extraFields={[
        {
          key: "link_improvement",
          label: "Brand Linking Recommendation",
          isBold: true,
        },
      ]}
    />
  );
};
export default LinkingStrategy;
