import { Box, Grid, Typography } from "@mui/material";
import TitlesGrid from "../../../../components/TitlesGrid/TitlesGrid";
import chatgptIcon from "../../../../../../assets/images/llmLogos/chatgpt.png";
import perplexityIcon from "../../../../../../assets/images/llmLogos/perplexity.png";
import geminiIcon from "../../../../../../assets/images/llmLogos/gemini.png";
import { mapLLmLabels } from "../../../../utils/DataUtils";

const LLMIconMapping = {
  ChatGpt: chatgptIcon,
  Gemini: geminiIcon,
  Perplexity: perplexityIcon,
};
const LLMGridRanking = ({ rankings }) => {
  return (
    <Box style={{ display: "flex", flexDirection: "column", rowGap: 10 }}>
      <TitlesGrid title2="LLM" />
      {rankings?.map((ranking, index) => (
        <Grid container justifyContent="space-between">
          <Grid item xs={1.5}>
            <Typography
              style={{ fontWeeight: 400, fontSize: 14 }}
              color="text.tertiary"
            >
              {index + 1}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              columnGap: 4,
            }}
          >
            <img
              src={ranking.icon || LLMIconMapping[ranking.label]}
              style={{ width: 14, height: 14 }}
              alt={ranking.label}
            />{" "}
            <Typography
              style={{ fontWeeight: 400, fontSize: 14 }}
              color="text.primary"
            >
              {mapLLmLabels[ranking.label]}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Typography
              style={{ fontWeeight: 400, fontSize: 14 }}
              color="text.secondary"
            >
              {Math.round(ranking.score)}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};
export default LLMGridRanking;
