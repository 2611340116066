import ListDisplay from "../../ListDisplay";

const Accuracy = ({ data, mapSections }) => {
  return (
    <ListDisplay
      data={data}
      mapSections={mapSections}
      excludedKeys={["priority"]}
      extraFields={[
        {
          key: "validation_sources",
          label: "Validating Sources ",
          isBold: true,
        },
        {
          key: "accuracy_optimizations.priority",
          label: "Priority",
          isBold: true,
        },
      ]}
      nestedKey="accuracy_optimizations"
    />
  );
};

export default Accuracy;
