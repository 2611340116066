import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import StrategicTextEdit from "./components/AeoSection/StrategicTextEdit";
import BrandAlignment from "./components/AeoSection/BrandAlignment";
import UserGeneratedContent from "./components/AeoSection/UserGeneratedContent/UserGeneratedContent";
import Sentiment from "./components/AeoSection/Sentiment";
import Freshness from "./components/ContentQualitySection/Freshness";
import Thoroughness from "./components/ContentQualitySection/Thoroughness";
import Accuracy from "./components/ContentQualitySection/Accuracy";
import Readability from "./components/ContentQualitySection/Readability";
import LinkingStrategy from "./components/AICrawlabilitySection/LinkingStrategy";
import TechnicalPerformance from "./components/AICrawlabilitySection/TechincalPerformance/TechnicalPerformance";
import StructuredDa from "./components/AICrawlabilitySection/StructuredData";
import SectionDivider from "../SectionDivider";

const mapTitle = {
  ai_enablement: "Answer Engine Optimization (AEO)",
  content_experience: "Content Quality & User Experience",
  crawl_architecture: "AI Crawlability",
};

const mapSections = {
  section: "Section to Edit",
  ai_enablement: "Answer Engine Optimization (AEO)",
  strategic_text_insertions: "Strategic Text Edit",
  original_text: "Original Text",
  optimized_text: "Optimized Text",
  priority: "Priority",
  expected_impact: "Implementation Impact",
  insertion_guidelines: "Optimization Tip",
  brand_alignment: "Brand Alignment & Consistency",
  misaligned_phrases: "Misaligned Content",
  user_generated_content: "User Generated Content",
  content_sentiment: "Content Sentiment",
  sentiment_score: "Sentiment Score",
  sentiment_analysis: "Sentiment Analysis",
  optimization_recommendations: "Optimization Recommendations",
  content_experience: "Content Quality & User Experience",
  freshness_optimizations: "Freshness",
  temporal_relevance: "Timeliness",
  recency_optimizations: "Recency Optimization",
  depth_enhancements: "Thoroughness",
  accuracy_corrections: "Accuracy",
  validation_sources: "Validating Sources",
  readability_improvements: "Readability Improvements",
  readability_optimizations: "Readability Optimizations",
  crawl_architecture: "AI Crawlability",
  current_implementation: "Current Implementation",
  ai_optimized_markup: "AI-optimized Markup",
  technical_performance: "Technical Performance",
  load_time_optimization: "Load Time Optimization",
  crawl_budget_recommendations: "Crawl Budget Allocation",
  linking_strategy: "Brand Linking Strategy",
  link_improvement: "Brand Linking Recommendation",
  structured_data: "Structured Data",
  target_schema: "Target Schema",
};

const componentMap = {
  strategic_text_insertions: StrategicTextEdit,
  brand_alignment: BrandAlignment,
  user_generated_content: UserGeneratedContent,
  content_sentiment: Sentiment,
  freshness_optimizations: Freshness,
  depth_enhancements: Thoroughness,
  accuracy_corrections: Accuracy,
  readability_improvements: Readability,
  linking_strategy: LinkingStrategy,
  technical_performance: TechnicalPerformance,
  structured_data: StructuredDa,
};
const isEmpty = (obj) =>
  obj == null ||
  obj === "" ||
  (Array.isArray(obj) && obj.length === 0) ||
  (typeof obj === "object" && Object.keys(obj).length === 0);

const isValidValue = (value) => !isEmpty(value);

const OwnedOperatedRecommendations = ({ data }) => {
  const theme = useTheme();

  return (
    !isEmpty(data) && (
      <Box sx={{ marginTop: 1 }}>
        {Object.keys(data).map((outerKey) => {
          const outerData = data[outerKey];

          // Remove empty inner sections
          const filteredInnerKeys = Object.keys(outerData).filter((innerKey) =>
            isValidValue(outerData[innerKey])
          );

          if (filteredInnerKeys.length === 0) return null;

          return (
            mapTitle[outerKey] && (
              <Box key={outerKey} sx={{ marginBottom: "30px" }}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: theme.palette.text.primary,
                    borderRadius: "10px",
                    backgroundColor: theme.palette.background.secondary,
                    padding: "5px 10px",
                  }}
                >
                  {mapTitle[outerKey]}
                </Typography>

                {filteredInnerKeys.map((innerKey, innerIndex) => {
                  const sectionData = outerData[innerKey];
                  const sectionTitle = mapSections[innerKey];
                  const Component = componentMap[innerKey];

                  return (
                    <Box
                      key={innerKey}
                      sx={{ marginTop: "20px", marginLeft: 1 }}
                    >
                      <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
                        {innerIndex + 1}. {sectionTitle}
                      </Typography>
                      {Component ? (
                        <Component
                          data={
                            innerKey === "technical_performance"
                              ? [sectionData]
                              : sectionData
                          }
                          mapSections={mapSections}
                        />
                      ) : null}
                      {innerIndex < filteredInnerKeys.length - 1 && (
                        <SectionDivider />
                      )}
                    </Box>
                  );
                })}
              </Box>
            )
          );
        })}
      </Box>
    )
  );
};

export default OwnedOperatedRecommendations;
