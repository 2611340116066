import ChartCard from "../../../../../AEO/components/ChartCard/ChartCard";
import { useTheme } from "@mui/material";
import { useGlobalFilters } from "../../../../../../contexts/GlobalFiltersContext";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { API_URL } from "../../../../../../api";
import RiskIcon from "../../../../../../CommonComponents/customIcons/RiskIcon";
import RiskAudit from "../../../../components/RiskAudit/RiskAudit";

const RiskAuditCard = () => {
  const theme = useTheme();
  const {
    date: globalDate,
    initialDate: globalInitialDate,
    llm,
    selectedCustomFilter,
    setSelectedCustomFilter,
  } = useGlobalFilters();
  const [date, setDate] = useState(globalDate);
  const [initialDate, setInitialDate] = useState(globalInitialDate);
  const [data, setData] = useState([]);
  const { brandId, topicName } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedLLM, setSelectedLLM] = useState(llm);
  const topic = topicName ? topicName.replace(/-/g, " ") : "";

  useEffect(() => {
    setSelectedLLM(llm);
  }, [llm]);

  useEffect(() => {
    setSelectedCustomFilter("Owned & Operated");
  }, []);

  useEffect(() => {
    const start_date = date[0] && date[0].format("YYYY-MM-DD");
    const end_date = date[1] && date[1].format("YYYY-MM-DD");
    const type = selectedCustomFilter.replace(" & ", ",");
    if (start_date && end_date) {
      const url = topic
        ? `${API_URL}/aeo/${brandId}/source-risk-audit/?type=${type}&llm=${selectedLLM}&startDate=${start_date}&endDate=${end_date}&topic=${encodeURIComponent(
            topic
          )}`
        : `${API_URL}/aeo/${brandId}/source-risk-audit/?llm=${selectedLLM}&startDate=${start_date}&endDate=${end_date}`;

      setLoading(true);
      axios
        .get(url)
        .then((response) => {
          setLoading(false);

          const data = response.data;
          setData(data);
        })
        .catch((error) => {
          setLoading(false);
          setError(true);
        });
    }
  }, [brandId, selectedLLM, topic, date, initialDate, selectedCustomFilter]);
  useEffect(() => {
    setInitialDate(globalInitialDate);
    setDate(globalDate);
  }, [globalInitialDate, globalDate]);

  return (
    <ChartCard
      icon={<RiskIcon color={theme.palette.text.primary} />}
      title="AI Visibility Impact (AVI) Assessment"
      tooltipMessagePrimary="Categorizes analyzed sources as High, Medium, or Low impact on your brand’s AI visibility, based on factors like LLM prominence, quality of mentions, and sentiment. The number in each category indicates how many sources fall into that level of impact."
      tooltipMessageSecondary="High indicates the sources are well-optimized for AI search, while low indicates the sources require optimization. "
      height={276}
      isLoading={loading}
      isError={error}
      setDate={setDate}
      date={date}
      initialDate={initialDate}
      hasCalendarSelect
      rowGap={5}
      hasLLMselect
      selectedLLM={selectedLLM}
      setSelectedLLM={setSelectedLLM}
      hasCustomFilter={topicName}
      customFilterOptions={["Owned & Operated", "Earned"]}
    >
      <RiskAudit values={data} />
    </ChartCard>
  );
};
export default RiskAuditCard;
