import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";
import SearchIcon from "../../../../../../CommonComponents/customIcons/SearchIcon";
import { useTheme } from "@emotion/react";

const CustomSearchInput = ({ searchQuery, setSearchQuery }) => {
  const theme = useTheme();
  return (
    <TextField
      variant="outlined"
      placeholder="Search files..."
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color={theme.palette.text.primary} />
          </InputAdornment>
        ),
      }}
      sx={{
        backgroundColor: "background.quaternary",
        borderRadius: "11px",
        width: "242px",
        "& .MuiOutlinedInput-root": {
          borderRadius: "11px",
          "& fieldset": {
            border: "none",
          },
          "&:hover fieldset": {
            border: "none",
          },
          "&.Mui-focused fieldset": {
            border: "none",
          },
        },
        "& .MuiInputAdornment-root": {
          color: "text.secondary",
        },
        "& input": {
          px: 1,
          py: 1,
          color: "text.primary",
        },
      }}
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
    />
  );
};

export default CustomSearchInput;
