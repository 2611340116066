import { Button, CircularProgress, useTheme } from "@mui/material";

const ChipButton = ({
  label,
  iconBefore,
  iconAfter,
  onClick,
  backgroundColor,
  textColor,
  borderColor,
  hoverBackgroundColor,
  hoverTextColor,
  hoverBorderColor,
  padding = "10px 20px",
  fontSize = "16px",
  fontWeight = 600,
  columnGap = 1,
  fullWidth = false,
  disabled,
  isLoading,
  borderRadius = "25px",
}) => {
  const theme = useTheme();
  return (
    <Button
      sx={{
        backgroundColor: disabled ? "background.disabled" : backgroundColor,
        color: disabled ? theme.palette.text.disabled : textColor,
        padding: padding,
        fontSize: fontSize,
        borderRadius: borderRadius,
        height: "auto",
        border: `1px solid ${disabled ? "background.disabled" : borderColor}`,
        fontWeight: fontWeight,
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        textTransform: "none",
        columnGap: columnGap,
        "&:hover": {
          backgroundColor: hoverBackgroundColor,
          color: hoverTextColor,
          borderColor: hoverBorderColor,
        },
      }}
      onClick={onClick}
      fullWidth={fullWidth}
      disabled={disabled}
    >
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress
            style={{
              width: 20,
              height: 20,
            }}
          />
        </div>
      ) : (
        <>
          {iconBefore}
          {label}
          {iconAfter}
        </>
      )}
    </Button>
  );
};
export default ChipButton;
