import { Typography, useTheme } from "@mui/material";

const SectionTitle = ({ title }) => {
  const theme = useTheme();

  return (
    <Typography
      sx={{
        fontWeight: 600,
        fontSize: 16,
        color: theme.palette.text.primary,
        borderRadius: "10px",
        backgroundColor: theme.palette.background.secondary,
        padding: "5px 10px",
      }}
    >
      {title}{" "}
    </Typography>
  );
};
export default SectionTitle;
