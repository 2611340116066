import axios from "axios";
export const API_URL = process.env.REACT_APP_API_URL;
export const ML_API_URL = process.env.REACT_APP_ML_API_URL;
export const ML_AEO_URL = process.env.REACT_APP_ML_AEO_URL;

export const api = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: "application/json",
  },
});

export const ml_api = axios.create({
  baseURL: ML_API_URL,
  headers: {
    Accept: "application/json",
  },
});
