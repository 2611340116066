import { CircularProgress, Grid, Typography } from '@mui/material';
import WorkspacePageTemplate from '../Workspace/components/PageTemplate/PageTemplate';
import { useTheme } from '@emotion/react';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useMemo } from 'react';
import WorkSpaceCard from '../Workspace/components/Card/Card';
import AuthorStampIcon from '../../CommonComponents/customIcons/AuthorStampIcon';

const ProfileSettings = () => {
  const theme = useTheme();
  const cards = useMemo(
    () => [
      {
        icon: <SettingsOutlinedIcon color={theme.palette.text.primary} />,
        title: 'Profile',
        description:
          'Craft engaging, informative, SEO-friendly blog posts, tailored to your audience',
        isIncomplete: '',
        navigateTo: 'profile',
      },
      {
        icon: <AuthorStampIcon color={theme.palette.text.primary} />,
        title: 'Author Stamp',
        description:
          'Help Goodie understand your writing style to create more personalized writing content',
        isIncomplete: '',
        navigateTo: 'author-stamp',
      },
    ],
    [theme]
  );
  return (
    <WorkspacePageTemplate title="Profile Settings">
      <Typography
        sx={{
          borderTop: `1px solid ${theme.palette.border.tertiary}`,
          pt: '20px',
          pb: '60px',
          fontSize: '14px',
          fontWeight: 450,
          lineHeight: '20px',
        }}
        color="text.tertiary"
      >
        All Settings
      </Typography>
      {false ? (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '60%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress
            style={{
              width: 20,
              height: 20,
              color: '#2D333A',
            }}
          />
        </div>
      ) : (
        <Grid container spacing={3} direction="row" alignItems="center">
          {cards.map((card, index) => {
            return (
              <Grid item xs={8} sm={4} md={4} key={index}>
                <WorkSpaceCard card={card} />
              </Grid>
            );
          })}
        </Grid>
      )}
    </WorkspacePageTemplate>
  );
};

export default ProfileSettings;
