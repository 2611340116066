import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  TextField,
  Card,
  useTheme,
  Grid,
  Avatar,
} from "@mui/material";
import WorkspacePageTemplate from "../../../Workspace/components/PageTemplate/PageTemplate";
import { FileUploadOutlined } from "@mui/icons-material";
import axios from "axios";
import ChipButton from "../../../../CommonComponents/ChipButton";
import { useUser } from "../../../../contexts/UserContext";
import { getInitials } from "../../../../utils/getInitials";
import { API_URL } from "../../../../api";
const Profile = () => {
  const [profileData, setProfileData] = useState({
    firstName: "",
    lastName: "",
    profilePicture: null,
  });
  const [uploadedImage, setUploadedImage] = useState();
  const [loading, setLoading] = useState();
  const [isSaving, setIsSaving] = useState();
  const [imageChanged, setImagedChanged] = useState();
  const { loggedInUser, updateUser } = useUser();
  const userId = loggedInUser.id;
  const theme = useTheme();

  const refetch = () => {
    setUploadedImage("");
    setLoading(true);
    axios
      .get(`${API_URL}/accounts/${userId}`)
      .then((response) => {
        const data = response.data;
        setProfileData({
          firstName: data.first_name || "",
          lastName: data.last_name || "",
          profilePicture: data.profile?.profile_picture || null,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching profile:", err);
        setLoading(false);
      });
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImagedChanged(true);
      setProfileData((prev) => ({
        ...prev,
        profilePicture: file,
      }));

      const reader = new FileReader();
      reader.onload = () => {
        setUploadedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    refetch();
  }, [userId]);
  const handleSave = () => {
    setIsSaving(true);
    const formData = new FormData();
    formData.append("first_name", profileData.firstName);
    formData.append("last_name", profileData.lastName);

    if (imageChanged && profileData.profilePicture) {
      formData.append("profile_picture", profileData.profilePicture);
    }

    axios
      .put(`${API_URL}/accounts/${userId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setIsSaving(false);
        setImagedChanged(false);
        updateUser(response.data);
        refetch();
      })
      .catch((err) => {
        console.error("Error updating profile:", err);
        setIsSaving(false);
      });
  };

  return (
    <WorkspacePageTemplate title="Profile">
      <Typography
        variant="subtitle1"
        sx={{
          fontSize: "14px",
          fontWeight: 450,
          maxWidth: "596px",
          mt: -4,
        }}
        color="text.secondary"
      >
        Manage your profile information including your name and profile picture.
      </Typography>

      <Card
        style={{
          borderRadius: "10px",
          padding: "20px",
          boxShadow: "none",
          marginTop: 40,
          backgroundColor: theme.palette.background.secondary,
        }}
      >
        <Grid container direction="row" spacing={{ xs: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Typography
              variant="h3"
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
                mb: 1,
              }}
              color="text.primary"
            >
              First Name
            </Typography>
            <TextField
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: `1px solid ${theme.palette.border.secondary}`,
                    borderRadius: "11px",
                  },
                  "&:hover fieldset": {
                    border: "1px solid",
                    borderColor: theme.palette.border.secondary,
                  },
                  "&.Mui-focused fieldset": {
                    border: "1px solid",
                    borderColor: theme.palette.border.secondary,
                  },
                },
              }}
              placeholder="Type here"
              value={profileData.firstName}
              onChange={(e) => {
                setProfileData((prev) => ({
                  ...prev,
                  firstName: e.target.value,
                }));
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Typography
              variant="h3"
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
                mb: 1,
              }}
              color="text.primary"
            >
              Last Name
            </Typography>
            <TextField
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: `1px solid ${theme.palette.border.secondary}`,
                    borderRadius: "11px",
                  },
                  "&:hover fieldset": {
                    border: "1px solid",
                    borderColor: theme.palette.border.secondary,
                  },
                  "&.Mui-focused fieldset": {
                    border: "1px solid",
                    borderColor: theme.palette.border.secondary,
                  },
                },
              }}
              placeholder="Type here"
              value={profileData.lastName}
              onChange={(e) => {
                setProfileData((prev) => ({
                  ...prev,
                  lastName: e.target.value,
                }));
              }}
            />
          </Grid>
        </Grid>
      </Card>

      <Card
        style={{
          borderRadius: "10px",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          boxShadow: "none",
          rowGap: 10,
          marginTop: 20,
          backgroundColor: theme.palette.background.secondary,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "44px",
            mb: "1px",
          }}
          color="text.primary"
        >
          Profile Image
        </Typography>
        <Grid container alignItems="start" spacing={2}>
          <Grid display={"flex"} justifyContent={"center"} item xs={12} lg={1}>
            <Avatar
              src={uploadedImage || profileData.profilePicture}
              sx={{ width: 65, height: 65, bgcolor: "background.quaternary" }}
            >
              {getInitials(loggedInUser.first_name)}
            </Avatar>
          </Grid>
          <Grid item xs={12} lg={11}>
            <Box
              sx={{
                border: "1px solid",
                borderColor: "border.tertiary",
                borderRadius: 2,
                padding: 3,
                textAlign: "center",
              }}
            >
              <FileUploadOutlined sx={{ fontSize: 25 }} />
              <input
                type="file"
                accept=".jpg,.jpeg,.png,.svg"
                style={{ display: "none" }}
                id="upload-file"
                onChange={handleFileChange}
              />
              <label htmlFor="upload-file" style={{ cursor: "pointer" }}>
                <Typography
                  sx={{ fontSize: 14, fontWeight: 500 }}
                  color="text.secondary"
                >
                  <span
                    style={{
                      color: theme.palette.text.brand,
                      marginRight: "2px",
                    }}
                  >
                    Click to upload
                  </span>
                  or drag and drop
                </Typography>
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: 400,
                    mt: 1,
                  }}
                  color="text.secondary"
                >
                  SVG, PNG, JPG (max. 800x800px)
                </Typography>
              </label>
            </Box>
          </Grid>
        </Grid>
      </Card>
      <div
        style={{
          display: "inline-flex",
          whiteSpace: "nowrap",
          marginTop: "20px",
        }}
      >
        <ChipButton
          label="Save Changes"
          disabled={isSaving}
          backgroundColor={theme.palette.background.brand}
          textColor={theme.palette.text.chiptext}
          hoverBackgroundColor={theme.palette.background.brandhover}
          hoverTextColor={theme.palette.text.chiptext}
          hoverBorderColor={theme.palette.background.brandhover}
          fullWidth
          onClick={() => handleSave()}
          isLoading={isSaving}
        />
      </div>
    </WorkspacePageTemplate>
  );
};

export default Profile;
