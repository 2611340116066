import { Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import {
  getStatusColor,
  DISPLAY_STATUSES,
} from "../../../../../../utils/sourceStatus";
const StatusTableCell = ({ status }) => {
  const theme = useTheme();
  // const statuses = Object.values(API_STATUSES);

  return (
    <Typography
      noWrap
      style={{
        maxWidth: "fit-content",
        borderRadius: 11.65,
        display: "flex",
        flexDirection: "row",
        columnGap: 7,
        fontSize: 12,
        fontWeight: 400,
        padding: "2px 7px",
        alignItems: "center",
        border: `1px solid ${theme.palette.border.secondary}`,
        color: theme.palette.text.primary,
      }}
    >
      <div
        style={{
          width: "5px",
          height: "5px",
          borderRadius: "50%",
          backgroundColor: getStatusColor(status, theme),
        }}
      ></div>{" "}
      {DISPLAY_STATUSES[status]}
    </Typography>
  );
};
export default StatusTableCell;
