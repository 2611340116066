import { Box, Typography } from "@mui/material";
import AnimationLoader from "../../../../../../CommonComponents/AnimationLoader/AnimationLoader";
import Recommendation from "./components/Recommendation/Recommendation";

const RecommendationsSection = ({ data, loading, isEarned }) => {
  return loading ? (
    <AnimationLoader />
  ) : (
    data && (
      <Box
        sx={{
          backgroundColor: "background.brand20",
          padding: "16px",
          borderRadius: "10px",
          mb: "10px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: 500,
            color: "text.brandbold",
            fontSize: "16px",
            lineHeight: "18px",
            mb: "8px",
          }}
        >
          Optimization Recommendation
        </Typography>
        <Recommendation data={data} isEarned={isEarned} />
      </Box>
    )
  );
};
export default RecommendationsSection;
