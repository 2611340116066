import React from "react";
import { Tabs, Tab, Box, useTheme } from "@mui/material";

const SubTab = ({ value, onChange, tabNames, totalCount }) => {
  const handleChange = (event, newValue) => {
    onChange(newValue.toLowerCase());
  };
  const theme = useTheme();
  return (
    <Tabs
      value={value.toLowerCase()}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
      TabIndicatorProps={{
        style: {
          backgroundColor: theme.palette.text.primary,
        },
      }}
      sx={{
        "& .MuiTabs-flexContainer": {
          justifyContent: "flex-start",
        },
        "& .MuiTab-root": {
          minWidth: "fit-content",
          color: "text.tertiary",
          textTransform: "none",
          fontWeight: "bold",
          fontSize: "14px",
          padding: "0px 20px 0px 0px",
        },
        "& .Mui-selected": {
          color: "text.primary",
        },
      }}
    >
      {tabNames.map((name) => (
        <Tab
          key={name}
          label={
            name === "Inbox" ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                <span>{name}</span>
                {totalCount > 0 && (
                  <Box
                    sx={{
                      backgroundColor: "background.brand20",
                      color: "text.brand",
                      fontWeight: "500",
                      fontSize: "16px",
                      borderRadius: "100px",
                      padding: "5px 8px",
                      lineHeight: "18px",
                    }}
                  >
                    {totalCount}
                  </Box>
                )}
              </Box>
            ) : (
              name
            )
          }
          value={name.toLowerCase()}
        />
      ))}
    </Tabs>
  );
};

export default SubTab;
