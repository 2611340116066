import { ListItem, ListItemText, Typography } from "@mui/material";

const listItemStyles = { py: 0.5, my: 0, pl: 0, ml: 0 };
const listItemTextStyles = { py: 0, my: 0 };
const typographyStyles = { fontWeight: 600, fontSize: 14 };

const ListItemWithText = ({ label, value, isBold = false }) => {
  if (!value) return null;

  return (
    <ListItem sx={listItemStyles}>
      <ListItemText
        sx={listItemTextStyles}
        primary={
          <Typography
            sx={{ ...typographyStyles, fontWeight: isBold ? 700 : 600 }}
          >
            • {label ? label + ":" : ""}{" "}
            <span style={{ fontWeight: 400, fontSize: 14 }}>{value}</span>
          </Typography>
        }
      />
    </ListItem>
  );
};

export default ListItemWithText;
