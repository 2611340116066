import ListDisplay from "../../ListDisplay";

const Thoroughness = ({ data, mapSections }) => {
  return (
    <ListDisplay
      data={data}
      mapSections={mapSections}
      nestedKey="recommended_additions"
    />
  );
};

export default Thoroughness;
