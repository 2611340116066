import { Box, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import ChipButton from "../../../../../../../../../../CommonComponents/ChipButton/ChipButton";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
const SectionWithCopy = ({ text, primaryText }) => {
  const theme = useTheme();
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 1000);
    });
  };

  const formatMessage = (text) => {
    const parts = text.split(/(\*\*.*?\*\*|\n)/g);
    return parts.map((part, index) => {
      if (part.startsWith("**") && part.endsWith("**")) {
        return <strong key={index}>{part.slice(2, -2)}</strong>;
      } else if (part === "\n") {
        return <br key={index} />;
      }
      return part;
    });
  };

  return (
    <Box
      sx={{
        padding: 2,
        borderRadius: "10px",
        backgroundColor: theme.palette.background.secondary,
        display: "flex",
        flexDirection: "column",
        rowGap: 3,
      }}
    >
      {primaryText}
      <Typography
        sx={{
          whiteSpace: "pre-wrap",
          fontSize: 14,
          lineHeight: 1.5,
        }}
      >
        {formatMessage(text)}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
        {copied && (
          <Typography
            sx={{
              color: theme.palette.text.secondary,
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
            }}
          >
            <CheckCircleOutlineIcon style={{ fontSize: 15 }} /> Copied to
            clipboard!
          </Typography>
        )}
        <ChipButton
          iconBefore={<ContentPasteOutlinedIcon style={{ fontSize: 15 }} />}
          label="Copy to Clipboard"
          backgroundColor={theme.palette.background.primary}
          textColor={theme.palette.text.secondary}
          padding="6px 10px"
          fontSize="14px"
          borderColor={theme.palette.border.secondary}
          hoverBackgroundColor={theme.palette.background.primaryhover}
          hoverTextColor={theme.palette.text.primary}
          fontWeight={450}
          columnGap={0.5}
          borderRadius="11px"
          onClick={() => handleCopy()}
        />
      </Box>
    </Box>
  );
};

export default SectionWithCopy;
