import { Typography } from "@mui/material";
import SectionWithCopy from "../../../../SectionWithCopy";
import ListItemWithText from "../../ListItemWithText";

const formatMessage = (jsonString) => {
  try {
    return JSON.stringify(JSON.parse(jsonString), null, 2);
  } catch (e) {
    return jsonString;
  }
};
const bulletPoints = ["target_schema", "current_implementation"];

const StructuredData = ({ data, mapSections }) => {
  return (
    <>
      {Object.entries(data[0])
        .filter(([key]) => bulletPoints.includes(key))
        .map(([key, value]) => (
          <ListItemWithText
            key={key}
            label={mapSections[key] || key}
            value={value}
          />
        ))}
      <Typography sx={{ fontWeight: 700, marginBottom: 1, fontSize: 14 }}>
        • AI-Optimized Markup:{" "}
      </Typography>
      <SectionWithCopy text={formatMessage(data[0].ai_optimized_markup)} />
    </>
  );
};

export default StructuredData;
