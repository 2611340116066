import { InputAdornment, TextField, useTheme } from "@mui/material";
import { Search } from "@mui/icons-material";

const SearchInput = ({
  searchQuery,
  setSearchQuery,
  placeholder = "Search files...",
  marginTop = 0,
  width = "388px",
}) => {
  const theme = useTheme();

  return (
    <TextField
      variant="outlined"
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            sx={{
              margin: 0,
              padding: 0,
            }}
          >
            <Search sx={{ color: theme.palette.text.tertiary, fontSize: 18 }} />
          </InputAdornment>
        ),
      }}
      sx={{
        backgroundColor: "background.quaternary",
        borderRadius: "11px",
        width: width,
        "& .MuiOutlinedInput-root": {
          borderRadius: "11px",
          "& fieldset": {
            border: "none",
          },
          "&:hover fieldset": {
            border: "none",
          },
          "&.Mui-focused fieldset": {
            border: "none",
          },
        },
        "& .MuiInputAdornment-root": {
          color: "text.secondary",
        },
        "& input": {
          padding: "12px 16px 13px 4px",
          color: "text.primary",
          fontSize: 14,
        },
        marginTop: marginTop,
      }}
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
    />
  );
};
export default SearchInput;
