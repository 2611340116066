import React from "react";
import { Box, useTheme } from "@mui/material";
import SummarySection from "./components/SummarySection";
import OutreachMessagesSection from "./components/OutreachMessagesSection";
import NewContentSuggesstionSection from "./components/NewContentSuggesstionSection";
import SectionDivider from "../SectionDivider";
import SectionTitle from "../SectionTitle";

const mapSections = {
  content_summary: "Source Summary",
  outreach_messages: "Recommended Outreach",
  content_type: "Source Category",
  outreach_method: "Method of Outreach",
  recipient: "Recipient",
  subject: "Subject",
  message_body: "Message Body",
  generated_content: "New Content Suggestion",
  headline: "Headline",
  introduction: "Introduction",
  key_points: "Key Points",
  conclusion: "Conclusion",
  expected_impact: "Implementation Impact",
  suggested_placement: "Suggested Placement",
};

const isEmpty = (obj) =>
  obj == null ||
  obj === "" ||
  (Array.isArray(obj) && obj.length === 0) ||
  (typeof obj === "object" && Object.keys(obj).length === 0);

const isValidValue = (value) => !isEmpty(value);

const EarnedRecommendations = ({ data }) => {
  return (
    !isEmpty(data) && (
      <Box sx={{ marginTop: 1 }}>
        <SectionTitle title="Source Summary" />
        <Box sx={{ marginTop: "20px" }}>
          <SummarySection summary={data.content_summary} />
        </Box>
        <SectionDivider />
        <SectionTitle title="Recommended Outreach" />
        <OutreachMessagesSection
          data={data.outreach_messages[0]}
          mapSections={mapSections}
        />{" "}
        <SectionDivider />
        <SectionTitle title="New Content Suggestion" />
        <NewContentSuggesstionSection
          data={data.generated_content[0]}
          mapSections={mapSections}
        />
      </Box>
    )
  );
};

export default EarnedRecommendations;
