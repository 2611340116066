import { Avatar, Box, Tooltip, useTheme } from "@mui/material";
import ArticalIcon from "../../../../../../CommonComponents/customIcons/ArticalIcon";
import BlogIcon from "../../../../../../CommonComponents/customIcons/BlogIcon";
import LandingPageIcon from "../../../../../../CommonComponents/customIcons/LandingPageIcon";
import OpenLinkIcon from "../../../../../../CommonComponents/customIcons/OpenLinkIcon";
import SocialMediaIcon from "../../../../../../CommonComponents/customIcons/SocialMediaIcon";
import TypographyTableCell from "../TypographyTableCell/TypographyTableCell";

const SourceTableCell = ({ value, category, navigate: url }) => {
  const theme = useTheme();
  return (
    <Tooltip
      title={
        <div>
          <div style={{ whiteSpace: "pre-wrap" }}>{url}</div>
        </div>
      }
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: 4,
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => window.open(url, "_blank", "noopener,noreferrer")}
      >
        <Avatar
          sx={{
            width: "20px",
            height: "20px",
            fill: "blue",
            textAlign: "center",
            fontWeight: 400,
            mr: 1,
          }}
          src={`https://www.google.com/s2/favicons?domain=${url}&sz=128`}
        />
        <TypographyTableCell value={value} />
        <OpenLinkIcon color={theme.palette.text.tertiary} />
      </Box>
    </Tooltip>
  );
};
export default SourceTableCell;
