import React from "react";

const AnalyticsIcon = ({ color = "#AFB1B7", width, height }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.66669 10.5582C4.9319 10.5582 5.18626 10.4572 5.3738 10.2773C5.56133 10.0974 5.66669 9.85334 5.66669 9.59891C5.66995 9.56701 5.66995 9.53488 5.66669 9.50298L7.5267 7.71863H7.68004H7.83337L8.90671 8.74831C8.90671 8.74831 8.90671 8.78028 8.90671 8.79947C8.90671 9.0539 9.01207 9.29791 9.1996 9.47782C9.38714 9.65773 9.64149 9.7588 9.90671 9.7588C10.1719 9.7588 10.4263 9.65773 10.6138 9.47782C10.8014 9.29791 10.9067 9.0539 10.9067 8.79947V8.74831L13.3334 6.40114C13.5312 6.40114 13.7245 6.34488 13.889 6.23947C14.0534 6.13406 14.1816 5.98423 14.2573 5.80893C14.333 5.63364 14.3528 5.44075 14.3142 5.25466C14.2756 5.06857 14.1804 4.89763 14.0405 4.76346C13.9007 4.6293 13.7225 4.53793 13.5285 4.50092C13.3345 4.4639 13.1334 4.4829 12.9507 4.55551C12.768 4.62812 12.6118 4.75108 12.5019 4.90884C12.392 5.0666 12.3334 5.25208 12.3334 5.44181C12.3301 5.47372 12.3301 5.50585 12.3334 5.53775L9.92671 7.84654H9.82005L8.66671 6.72092C8.66671 6.46649 8.56135 6.22248 8.37381 6.04257C8.18627 5.86266 7.93192 5.76159 7.6667 5.76159C7.40148 5.76159 7.14713 5.86266 6.95959 6.04257C6.77205 6.22248 6.6667 6.46649 6.6667 6.72092L4.66669 8.63958C4.40147 8.63958 4.14711 8.74065 3.95958 8.92056C3.77204 9.10047 3.66668 9.34448 3.66668 9.59891C3.66668 9.85334 3.77204 10.0974 3.95958 10.2773C4.14711 10.4572 4.40147 10.5582 4.66669 10.5582ZM13.6667 13.1165H2.33334V2.24405C2.33334 2.07443 2.2631 1.91175 2.13808 1.79181C2.01305 1.67187 1.84348 1.60449 1.66667 1.60449C1.48986 1.60449 1.32029 1.67187 1.19526 1.79181C1.07024 1.91175 1 2.07443 1 2.24405V13.756C1 13.9256 1.07024 14.0883 1.19526 14.2082C1.32029 14.3282 1.48986 14.3956 1.66667 14.3956H13.6667C13.8435 14.3956 14.0131 14.3282 14.1381 14.2082C14.2632 14.0883 14.3334 13.9256 14.3334 13.756C14.3334 13.5864 14.2632 13.4237 14.1381 13.3038C14.0131 13.1838 13.8435 13.1165 13.6667 13.1165Z"
      fill={color}
    />
  </svg>
);

export default AnalyticsIcon;
