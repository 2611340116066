import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import perplexityIcon from "../../../../assets/images/llmLogos/perplexity.png";
import geminiIcon from "../../../../assets/images/llmLogos/gemini.png";
import chatgptIcon from "../../../../assets/images/llmLogos/chatgpt.png";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ML_AEO_URL } from "../../../../api";
import { useGlobalFilters } from "../../../../contexts/GlobalFiltersContext";
import { dummyRegionsData } from "../../consts/consts";

const LLMRankingTable = ({ rankingBy = "Language" }) => {
  const { noData } = useGlobalFilters();
  const [data, setData] = useState(noData ? dummyRegionsData : []);
  const { brandId } = useParams();

  useEffect(() => {
    axios
      .post(`${ML_AEO_URL}/aeo/brand-visibility-regions`, {
        brandId,
      })
      .then((response) => {
        const data = response.data;
        setData(data);
      })
      .catch((error) => {
        console.error("Error fetching data", error);
      });
  }, [brandId]);

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{rankingBy}</TableCell>
            <TableCell
              align="right"
              style={{
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: 3,
                }}
              >
                <img
                  src={chatgptIcon}
                  style={{ width: 14, height: 14 }}
                  alt="ChatGpt"
                />{" "}
                ChatGPT
              </div>
            </TableCell>
            <TableCell
              align="right"
              style={{
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: 3,
                }}
              >
                <img
                  src={perplexityIcon}
                  style={{ width: 14, height: 14 }}
                  alt="Perplexity"
                />{" "}
                Perplexity
              </div>
            </TableCell>
            <TableCell
              align="right"
              style={{
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: 3,
                }}
              >
                <img
                  src={geminiIcon}
                  style={{ width: 14, height: 14 }}
                  alt="Gemini"
                />{" "}
                Gemini
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  fontSize: 14,
                  color: "text.tertiary",
                }}
              >
                {row.label}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontSize: 14,
                  color: "text.tertiary",
                }}
              >
                {Math.round(row.scores["ChatGpt"])}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontSize: 14,
                  color: "text.tertiary",
                }}
              >
                {Math.round(row.scores["Perplexity"])}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontSize: 14,
                  color: "text.tertiary",
                }}
              >
                {Math.round(row.scores["Gemini"])}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default LLMRankingTable;
