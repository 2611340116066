import ListDisplay from "../../ListDisplay";

const StrategicTextEdit = ({ data, mapSections }) => {
  return (
    <ListDisplay
      data={data}
      mapSections={mapSections}
      excludedKeys={["section", "priority"]}
      extraFields={[{ key: "priority", label: "Priority", isBold: true }]}
    />
  );
};

export default StrategicTextEdit;
