import PromptCounter from "../../../../CommonComponents/PromptCounter";

const ScoreCount = ({ loading, prompt }) => {
  return (
    <PromptCounter
      title={"Source Count"}
      prompt={prompt}
      loading={loading}
      tooltipMessage={"The total number of sources analyzed within the topic."}
    />
  );
};
export default ScoreCount;
