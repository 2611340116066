import { Typography, useTheme } from "@mui/material";

const SummarySection = ({ summary }) => {
  const theme = useTheme();
  return (
    <Typography
      sx={{ color: theme.palette.text.primary, fontWeight: 400, fontSize: 14 }}
    >
      {summary}
    </Typography>
  );
};
export default SummarySection;
