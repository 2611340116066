import { Avatar, Card, Typography, useTheme } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import { Box } from "@mui/system";

const ShortcutCard = ({ card, disabled = false }) => {
  const { icon, title, description, backgroundColor, isReady, onClick } = card;
  const theme = useTheme();

  return (
    <Card
      style={{
        borderRadius: "12px",
        padding: "21px 33px 18px 23px",
        display: "flex",
        flexDirection: "column",
        cursor: disabled ? "not-allowed" : !isReady ? "" : "pointer",
        boxShadow: "none",
        position: "relative",
        rowGap: 10,
        backgroundColor: theme.palette.background.secondary,
      }}
      onClick={(e) => {
        if (!disabled) {
          onClick(e);
        }
      }}
    >
      {(disabled || !isReady) && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            zIndex: 2,
            borderRadius: "12px",
          }}
        />
      )}

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            width: "40px",
            height: "40px",
            backgroundColor: "background.tertiary",
            borderRadius: "50%",
          }}
        >
          {icon}
        </Box>
        {!disabled && !isReady && (
          <Typography
            sx={{
              fontSize: "12.95px",
              fontWeight: 500,
              borderRadius: "28.68px",
              mb: 1,
            }}
            color="text.tertiary"
          >
            Coming Soon
          </Typography>
        )}
      </div>

      <Typography
        variant="h3"
        sx={{
          fontSize: 22,
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          columnGap: 1,
          marginTop: 1,
          zIndex: 1,
          fontWeight: 450,
        }}
        color="text.primary"
      >
        {title} <EastIcon />
      </Typography>
      <Typography
        variant="h4"
        style={{
          fontSize: 14,
          zIndex: 1,
          fontWeight: 500,
        }}
        color="text.secondary"
      >
        {description}
      </Typography>
    </Card>
  );
};

export default ShortcutCard;
