import React from "react";
import { Tabs, Tab, useTheme } from "@mui/material";

const getTab = (tab) => {
  if (
    tab === "owned" ||
    tab === "operated" ||
    tab === "owned & operated sources"
  )
    return "owned & operated sources";
  else return "earned sources";
};
const MainTab = ({ value, onChange, tabNames }) => {
  const theme = useTheme();
  const handleChange = (event, newValue) => {
    onChange(getTab(newValue.toLowerCase()));
  };

  return (
    <Tabs
      value={getTab(value.toLowerCase())}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
      TabIndicatorProps={{
        style: { display: "none" },
      }}
      sx={{
        "& .MuiTabs-flexContainer": {
          justifyContent: "flex-start",
        },
        "& .MuiTab-root": {
          minWidth: "fit-content",
          color: "text.tertiary",
          textTransform: "none",
          fontWeight: "600",
          fontSize: "24px",
          padding: "0px 30px 0px 0px",
          letterSpacing: "-0.48px",
        },
        "& .Mui-selected": {
          color: `${theme.palette.text.primary} !important`,
        },
      }}
    >
      {tabNames.map((name) => (
        <Tab key={name} label={name} value={name.toLowerCase()} />
      ))}
    </Tabs>
  );
};

export default MainTab;
