import React from "react";
import {
  TableRow,
  TableCell,
  Typography,
  Box,
  IconButton,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RiskTableCell from "../../../SourceTrackingTable/components/RiskTableCell";

const DataRow = ({ row, editReference, onSelect, selected, index }) => {
  const {
    source_url,
    impact_score,
    optimization_priority,
    id,
    source_status,
    isOpened,
  } = row;
  const theme = useTheme();
  return (
    <TableRow
      sx={{
        "& > td": {
          padding: 0,
          border: "none",
        },
      }}
      onClick={onSelect}
      style={{ cursor: "pointer" }}
    >
      <TableCell
        sx={{
          padding: 0,
        }}
      >
        <Box
          sx={{
            backgroundColor: selected
              ? "background.brand20"
              : "background.secondary",
            borderRadius: "10px",
            padding: "12px 16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              color: "text.primary",
              flex: 1,
              wordBreak: "break-word",
            }}
          >
            {source_url}{" "}
            {!isOpened && (
              <span
                style={{
                  fontSize: 12,
                  backgroundColor: theme.palette.background.brand20,
                  color: theme.palette.text.brandBold,
                  borderRadius: "30px",
                  padding: "5px 8px",
                }}
              >
                New!
              </span>
            )}
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: "text.tertiary",
                fontSize: "12px",
                lineHeight: "14px",
              }}
            >
              AVI
            </Typography>
            <RiskTableCell
              value={impact_score}
              piriority={optimization_priority}
            />
          </Box>
          {source_status !== "Archived" && (
            <IconButton
              size="small"
              onClick={(e) => {
                editReference(id, { source_status: "Archived" }, index);
              }}
              sx={{
                color: "text.primary",
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default DataRow;
