import { Box, Grid, LinearProgress, Typography, useTheme } from "@mui/material";
import chatgptIcon from "../../../../assets/images/llmLogos/chatgpt.png";
import perplexityIcon from "../../../../assets/images/llmLogos/perplexity.png";
import geminiIcon from "../../../../assets/images/llmLogos/gemini.png";

const LLMIconMapping = {
  ChatGpt: chatgptIcon,
  Gemini: geminiIcon,
  Perplexity: perplexityIcon,
};

const RankingWithProgressLine = ({ rankings }) => {
  const theme = useTheme();
  const getProgressLineColor = (percentage) => {
    if (percentage >= 80) {
      return theme.palette.circle.positive;
    } else if (percentage >= 50) {
      return theme.palette.circle.neutral;
    } else {
      return theme.palette.circle.negative;
    }
  };
  const getProgressLineBackgroundcolor = (percentage) => {
    if (percentage >= 80) {
      return theme.palette.background.positive;
    } else if (percentage >= 50) {
      return theme.palette.background.neutral;
    } else {
      return theme.palette.background.negative;
    }
  };
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: 10,
        marginTop: 7.5,
      }}
    >
      {rankings?.map((ranking, index) => (
        <Grid
          container
          direction="row"
          sx={{
            alignItems: "center",
          }}
        >
          <Grid item xs={1}>
            <Typography
              style={{ fontWeeight: 400, fontSize: 14 }}
              color="text.tertiary"
            >
              {index + 1}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2.5}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              columnGap: 4,
            }}
          >
            {(ranking.icon || LLMIconMapping[ranking.label]) && (
              <img
                src={ranking.icon || LLMIconMapping[ranking.label]}
                style={{ width: 14, height: 14 }}
                alt={ranking.label}
              />
            )}

            <Typography
              style={{ fontWeeight: 400, fontSize: 14 }}
              color="text.primary"
            >
              {ranking.label}
            </Typography>
          </Grid>
          <Grid item xs={6.8}>
            <LinearProgress
              value={Math.round(ranking.score)}
              variant="determinate"
              sx={{
                borderRadius: 5,
                height: 10,
                width: "100%",
                alignSelf: "center",
                backgroundColor: getProgressLineBackgroundcolor(ranking.score),
                "& .MuiLinearProgress-bar": {
                  backgroundColor: getProgressLineColor(ranking.score),
                  borderRadius: 5,
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={1.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Typography
              style={{ fontWeeight: 400, fontSize: 14 }}
              color="text.secondary"
            >
              {" "}
              {Math.round(ranking.score)}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};
export default RankingWithProgressLine;
