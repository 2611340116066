import ListDisplay from "../../ListDisplay";

const UserGeneratedContent = ({ data, mapSections }) => {
  return (
    <ListDisplay
      data={data}
      mapSections={mapSections}
      excludedKeys={["section", "priority"]}
    />
  );
};

export default UserGeneratedContent;
