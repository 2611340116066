import { useMemo } from "react";
import { List, ListItemText, Typography, ListItem, Box } from "@mui/material";
import ListItemWithText from "../ListItemWithText";

const listStyles = { py: 0, my: 0 };
const nestedListStyles = { pl: 2, py: 0, my: 0 };
const listItemStyles = { py: 0.5, my: 0, pl: 0, ml: 0 };
const listItemTextStyles = { py: 0, my: 0 };
const typographyStyles = { fontWeight: 600, fontSize: 14 };

const filterValidEntries = (item, excludedKeys = []) => {
  return Object.entries(item).filter(
    ([key, value]) =>
      !excludedKeys.includes(key) &&
      value &&
      (Array.isArray(value) ? value.length > 0 : true)
  );
};

const getNestedItem = (item, nestedKey) => {
  if (!nestedKey || !item[nestedKey]) {
    return item;
  }

  const nestedData = item[nestedKey];

  if (Array.isArray(nestedData) && typeof nestedData[0] === "string") {
    return nestedData;
  }

  if (Array.isArray(nestedData) && typeof nestedData[0] === "object") {
    return nestedData[0] || {};
  }

  return item;
};

const getNestedValue = (obj, path) => {
  if (!path) return obj;
  return path.split(".").reduce((acc, key) => {
    if (Array.isArray(acc)) {
      acc = acc[0];
    }
    return acc?.[key];
  }, obj);
};

const ListDisplay = ({
  data,
  mapSections,
  excludedKeys = [],
  extraFields = [],
  nestedKey = null,
}) => {
  const filteredData = useMemo(() => {
    return data?.filter((item) =>
      Object.values(item).some(
        (value) => value && (Array.isArray(value) ? value.length > 0 : true)
      )
    );
  }, [data]);

  return filteredData.map((item) => {
    const selectedItem = getNestedItem(item, nestedKey);

    return (
      <List key={item.id} sx={listStyles}>
        {item.section && (
          <ListItemWithText label="Section to Edit" value={item.section} />
        )}

        {extraFields.map(({ key, label, isBold }) => {
          const fieldValue = getNestedValue(item, key);

          return (
            <div key={key}>
              <ListItem sx={listItemStyles}>
                <ListItemText
                  sx={listItemTextStyles}
                  primary={
                    <Typography
                      sx={{
                        ...typographyStyles,
                        fontWeight: isBold ? 700 : 600,
                      }}
                    >
                      • {label ? `${label}: ` : ""}
                      {Array.isArray(fieldValue) ? (
                        fieldValue.length === 1 ? (
                          typeof fieldValue[0] === "string" ? (
                            <span style={{ fontWeight: 400, fontSize: 14 }}>
                              {fieldValue[0]}
                            </span>
                          ) : typeof fieldValue[0] === "object" &&
                            fieldValue[0] !== null &&
                            Object.keys(fieldValue[0]).length > 1 ? (
                            <Box sx={{ ml: 2 }}>
                              {Object.entries(fieldValue[0]).map(
                                ([key, value]) => (
                                  <ListItemWithText
                                    key={key}
                                    label={mapSections[key] || key}
                                    value={value}
                                  />
                                )
                              )}
                            </Box>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )
                      ) : typeof fieldValue === "string" || "int" || "float" ? (
                        <span style={{ fontWeight: 400, fontSize: 14 }}>
                          {fieldValue}
                        </span>
                      ) : typeof fieldValue === "object" &&
                        fieldValue !== null ? (
                        <Box sx={{ ml: 2 }}>
                          {Object.entries(fieldValue).map(([key, value]) => (
                            <ListItemWithText
                              key={key}
                              label={mapSections[key] || key}
                              value={value}
                            />
                          ))}
                        </Box>
                      ) : (
                        ""
                      )}
                    </Typography>
                  }
                />
              </ListItem>

              {Array.isArray(fieldValue) && fieldValue.length > 1 && (
                <List sx={{ p: 0, pl: 2 }}>
                  {fieldValue.map((value, index) => (
                    <ListItemWithText key={`${key}-${index}`} value={value} />
                  ))}
                </List>
              )}
            </div>
          );
        })}

        {/* Nested Recommendations */}
        <List sx={nestedListStyles}>
          {Array.isArray(selectedItem)
            ? selectedItem.map((text, index) => (
                <ListItemWithText key={index} value={text} />
              ))
            : filterValidEntries(selectedItem, excludedKeys).map(
                ([key, value]) =>
                  mapSections[key] && (
                    <ListItemWithText
                      key={key}
                      label={mapSections[key]}
                      value={value}
                    />
                  )
              )}
        </List>
      </List>
    );
  });
};

export default ListDisplay;
