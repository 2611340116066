import { Grid, useTheme } from "@mui/material";
import PageLayout from "../../components/PageLayout/PageLayout";
import { GlobalFiltersProvider } from "../../../../contexts/GlobalFiltersContext";
import AverageLineCard from "../../../AEO/Pages/Dashboard/Components/AverageLineCard/AverageLineCard";
import VisibilityIcon from "../../../../CommonComponents/customIcons/VisibilityIcon";
import SentimentIcon from "../../../../CommonComponents/customIcons/SentimentIcon";
import RankingWheelsCard from "../../../AEO/components/RankingWheelsCard/RankingWheelsCard";
import TopicBrandRecognition from "./components/TopicBrandRecognition";
import RiskAuditCard from "../Overview/components/RiskAuditCard/RiskAuditCard";
import SourceTrackingCard from "./components/SourceTrackingCard/SourceTrackingCard";
import { useParams } from "react-router-dom";

const TopicDetails = () => {
  const theme = useTheme();
  const { topicName } = useParams();
  const name = topicName.replace(/-/g, " ");

  return (
    <GlobalFiltersProvider>
      <PageLayout title={name} hasGlobalFilters topic={name}>
        <Grid
          container
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={8}
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: 15,
            }}
          >
            <Grid
              container
              direction="row"
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item xs={5.8}>
                <AverageLineCard
                  icon={<VisibilityIcon color={theme.palette.text.primary} />}
                  title="Topic Visibility"
                  tooltipPrimary="Measures your brand’s prominence within this search topic by LLM within the past 24 hours.
Higher scores indicate stronger visibility."
                  tooltipSecondary="Scores are out of 100."
                  index={0}
                  widget_type="Visibility"
                  topic={name}
                />
              </Grid>
              <Grid item xs={5.8}>
                <AverageLineCard
                  icon={<SentimentIcon color={theme.palette.text.primary} />}
                  title="Topic Sentiment"
                  tooltipPrimary="Measures the LLM sentiment towards your brand within this search topic over the past 24 hours. 
Higher scores reflect positive sentiment, while lower scores indicate negative sentiment."
                  tooltipSecondary="Scores are out of 100."
                  index={1}
                  topic={name}
                  widget_type="Sentiment"
                />
              </Grid>{" "}
            </Grid>
            <TopicBrandRecognition />
          </Grid>
          <Grid item xs={3.8}>
            <RankingWheelsCard
              icon={<VisibilityIcon color={theme.palette.text.primary} />}
              title="Topic Visibility Ranking"
              tooltipPrimary="Displays your brand’s rank against competitors for this search topic by LLM over the past 24 hours. 
Higher scores indicate a stronger position compared to competitors."
              tooltipSecondary="Scores are out of 100."
              height={471}
              hasRankingTypography
              wheelsGrid={12}
              isVertical
              comparsion_type="Competitors"
            />
          </Grid>{" "}
        </Grid>

        <RiskAuditCard />
        <SourceTrackingCard />
      </PageLayout>
    </GlobalFiltersProvider>
  );
};
export default TopicDetails;
