import ListDisplay from "../../ListDisplay";

const BrandAlignment = ({ data, mapSections }) => {
  return (
    <ListDisplay
      data={data}
      mapSections={mapSections}
      excludedKeys={["priority"]}
      extraFields={[
        { key: "ai_aligned_replacements.priority", label: "Priority", isBold: true },
        { key: "misaligned_phrases", label: "Misaligned Phrases", isBold: true },
      ]}
      nestedKey="ai_aligned_replacements"
    />
  );
};

export default BrandAlignment;
