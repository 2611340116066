import React, { useState, useEffect, useRef, useMemo } from "react";
import axios from "axios";
import {
  Box,
  Paper,
  IconButton,
  Divider,
  Table,
  TableBody,
  Typography,
  Modal,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MainTab from "./components/MainTab/maintab";
import SubTab from "./components/SubTab/subtab";
import DataRow from "./components/Datarow/datarow";
import Opportunity from "./components/OpportunityBox/opportunity";
import { API_URL } from "../../../../api";
import { useParams } from "react-router-dom";
import AnimationLoader from "../../../../CommonComponents/AnimationLoader/AnimationLoader";
import { useGlobalFilters } from "../../../../contexts/GlobalFiltersContext";

const isEmpty = (obj) => Object.keys(obj).length === 0;

const mapType = {
  Operated: "operated,owned",
  Owned: "operated,owned",
  Earned: "earned",
  "owned & operated sources": "operated,owned",
  "earned sources": "earned",
};

const PopUp = ({ open, onClose, initialSelected = {}, setInitialSelected }) => {
  const [mainTab, setMainTab] = useState("Owned");
  const [subTab, setSubTab] = useState("Inbox");
  const [data, setData] = useState({ owned: {}, earned: {} });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const { brandId, topicName } = useParams();
  const containerRef = useRef(null);
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [isOpportunityVisible, setIsOpportunityVisible] = useState(false);
  const topic = topicName ? topicName.replace(/-/g, " ") : "";

  const { date } = useGlobalFilters();

  const fetchData = async (page) => {
    page === 1 && setLoading(true);
    setError(null);
    const status = subTab.replace(/\s+/g, "");
    const start_date = date[0] && date[0].format("YYYY-MM-DD");
    const end_date = date[1] && date[1].format("YYYY-MM-DD");
    if (start_date && end_date) {
      try {
        // Fetch paginated data
        const response = await axios.get(
          `${API_URL}/aeo/${brandId}/source-recommendation/`,
          {
            params: {
              llm: "ChatGPT",
              status: status.charAt(0).toUpperCase() + status.slice(1),
              type: mapType[mainTab] || "operated,owned",
              page: page,
              page_size: 10,
              topic: topic,
              startDate: start_date,
              endDate: end_date,
            },
          }
        );

        const { results, count } = response.data;
        subTab.toLowerCase() === "inbox" && setTotalCount(count);

        let newData = results;
        if (initialSelected) {
          const isSelectedRowInPage = results.some(
            (item) => item.id === initialSelected.id
          );

          if (!isSelectedRowInPage && initialSelected?.id) {
            const selectedRowResponse = await axios.get(
              `${API_URL}/aeo/references/${initialSelected.id}`
            );

            const selectedRowData = selectedRowResponse.data;

            newData.unshift(selectedRowData);
          }
        }

        setData((prevData) => ({
          ...prevData,
          [mainTab]: {
            ...prevData[mainTab],
            [subTab]:
              page === 1
                ? newData
                : [...(prevData[mainTab]?.[subTab] || []), ...newData],
          },
        }));
      } catch (err) {
        setError("Failed to fetch data. Please try again later.");
      } finally {
        setLoading(false);
        setIsLoadingNextPage(false);
      }
    }
  };

  useEffect(() => {
    if (open) {
      fetchData(page);
    } else {
      setData([]);
      !isEmpty(initialSelected) && setInitialSelected({});
      setMainTab("");
      setSubTab("");
    }
  }, [open, page, mainTab, subTab, date]);

  useEffect(() => {
    const container = containerRef.current;
    if (container && hasScrolled) {
      const handleScroll = () => {
        const scrollThreshold = 100;
        const distanceFromBottom =
          container.scrollHeight - container.scrollTop - container.clientHeight;

        if (distanceFromBottom <= scrollThreshold && !isLoadingNextPage) {
          setIsLoadingNextPage(true);
          setPage((prevPage) => prevPage + 1);
        }
      };

      container.addEventListener("scroll", handleScroll);

      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [isLoadingNextPage, hasScrolled]);

  const handleTabChange = () => {
    !isEmpty(initialSelected) && setInitialSelected({});
    setSelectedRow({});
    setData([]);
    setPage(1);
    setIsLoadingNextPage(false);
    setHasScrolled(false);
    setIsOpportunityVisible(false);
  };

  const handleMainTabChange = (newValue) => {
    handleTabChange();
    setMainTab(newValue.toLowerCase());
    setSubTab("Inbox");
  };

  const handleSubTabChange = (newValue) => {
    handleTabChange();
    setSubTab(newValue);
  };

  const handleRemoveRow = (index) => {
    const updatedSubTabData = data[mainTab][subTab].filter(
      (_, rowIndex) => rowIndex !== index
    );

    setData((prevData) => ({
      ...prevData,
      [mainTab]: {
        ...prevData[mainTab],
        [subTab]: updatedSubTabData,
      },
    }));
    if (selectedRow === data[mainTab][subTab][index]) {
      setSelectedRow(null);
    }
  };

  const editReference = (id, values, index, isEdit) => {
    axios
      .patch(`${API_URL}/aeo/references/${id}`, values)
      .then((response) => {
        const updatedItem = { ...data[mainTab][subTab][index], ...values };

        setData((prevData) => ({
          ...prevData,
          [mainTab]: {
            ...prevData[mainTab],
            [subTab]: [
              ...prevData[mainTab][subTab].slice(0, index),
              updatedItem,
              ...prevData[mainTab][subTab].slice(index + 1),
            ],
          },
        }));

        if (!isEdit) {
          handleRemoveRow(index);
        }
      })
      .catch((err) => {
        console.error("Failed to update the item:", err);
      });
  };

  useEffect(() => {
    if (!isEmpty(initialSelected)) {
      setMainTab(initialSelected?.type);
      setSubTab(initialSelected?.source_status);
    }
  }, [initialSelected]);

  const currentData = useMemo(() => {
    return data[mainTab]?.[subTab] || [];
  }, [data, mainTab, subTab]);

  useEffect(() => {
    setIsOpportunityVisible(!isEmpty(selectedRow) || !isEmpty(initialSelected));
  }, [selectedRow, initialSelected]);

  if (!open) return null;
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "92%",
    borderRadius: "35px",
    boxShadow: 24,
    height: "80%",
    outline: "none",
    backgroundColor: "background.primary",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          <IconButton onClick={onClose} sx={{ color: "text.secondary" }}>
            <CloseIcon />
          </IconButton>
        </Box>

        <MainTab
          value={mainTab}
          onChange={handleMainTabChange}
          tabNames={["Owned & Operated Sources", "Earned Sources"]}
        />

        <SubTab
          value={subTab}
          onChange={handleSubTabChange}
          tabNames={["Inbox", "In Progress", "Completed", "Archived"]}
          data={data[mainTab]}
          totalCount={totalCount}
        />

        <Divider
          sx={{ marginBottom: "20px", backgroundColor: "border.tertiary" }}
        />

        <Typography
          color="text.secondary"
          sx={{
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
          }}
        >
          Below are new sources that Goodie identified room for optimizations.
          New optimizations are generated every 12 hours.
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            height: "400px",
            mt: "10px",
          }}
        >
          <Box
            ref={containerRef}
            sx={{
              flex: 1,
              overflowY: "auto",
              maxHeight: "400px",
            }}
            onScroll={() => setHasScrolled(true)}
          >
            <Table
              sx={{
                borderSpacing: "0px 10px ",
                borderCollapse: "separate",
              }}
            >
              <TableBody>
                {!loading &&
                  mainTab &&
                  subTab &&
                  (error ? (
                    <Box
                      sx={{
                        textAlign: "center",
                        padding: "16px",
                        color: "error.main",
                      }}
                    >
                      {error}
                    </Box>
                  ) : currentData?.length > 0 ? (
                    currentData?.map((item, index) => (
                      <DataRow
                        key={item.id}
                        row={item}
                        index={index}
                        editReference={() =>
                          editReference(
                            item.id,
                            { source_status: "Archived" },
                            index
                          )
                        }
                        onSelect={() => {
                          !isEmpty(initialSelected) && setInitialSelected({});
                            editReference(
                              item.id,
                              { isOpened: true },
                              index,
                              true
                            );
                          setSelectedRow(item);
                        }}
                        selected={
                          !isEmpty(initialSelected)
                            ? initialSelected?.id === item?.id
                            : selectedRow?.id === item?.id
                        }
                      />
                    ))
                  ) : (
                    <Box
                      sx={{
                        textAlign: "center",
                        padding: "16px",
                        color: "text.secondary",
                      }}
                    >
                      No data available for this tab.
                    </Box>
                  ))}

                {(isLoadingNextPage || loading) && (
                  <Box sx={{ textAlign: "center", padding: "16px" }}>
                    <AnimationLoader />
                  </Box>
                )}
              </TableBody>
            </Table>
          </Box>

          {isOpportunityVisible && !loading && (
            <>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  backgroundColor: "border.tertiary",
                  marginX: "16px",
                  mr: "16px",
                  mt: "10px",
                }}
              />
              <Opportunity
                selectedRow={
                  !isEmpty(initialSelected) ? initialSelected : selectedRow
                }
                subTab={subTab}
              />
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default PopUp;
